import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  IconButton,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { useQuery } from "@apollo/client"
import {
  GETALLAGENTS,
  GETALLFUNDSREQUEST,
  GETALLFUNDSREQUESTPENDING,
  GETBALANCEOFUSERS,
  GETCOMPANYSTAFF,
} from "../../../GraphQl/queries"
import { AgentType, FundsType } from "../accountfunding"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../../util"

import { SubmitHandler, useForm } from "react-hook-form"

import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

type BalanceType = {
  name: string
  balance: string
  cash_limit: string
  cash_imprest: string
  remaining_cash_balance:string;
  amount_used:string;
  record_id: string
  record_type: string
}

type FormType = {
  amount: number
}

const AccountBalances = () => {
  const [submitting, setSubmitting] = useState(false)
  const [searchRequestText, setRequestText] = useState("")
  const [searchBalanceText, setBalanceText] = useState("")
  const [activePage, setActivePage] = useState(1)

  // const [formData, setFormData] = useState<PinType | null>(null)


  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const [activePage2, setActivePage2] = useState(1)
  const PAGE_SIZE = 10

  const {
    register,
    reset,
    watch,
    handleSubmit,
    formState: { isSubmitting, errors },
  } = useForm<FormType>()

  const {
    data: balances,
    loading: loadingBalances,
    refetch: refetchBalance,
  } = useQuery<{
    positive_balance_view: BalanceType[]
  }>(GETBALANCEOFUSERS, {
    variables: {
      company_id: companyId,
    },
  })

  const {
    data: funds,
    loading: loadingFunds,
    refetch: refchFunds,
  } = useQuery<{
    view_fund_account_details: FundsType[]
  }>(GETALLFUNDSREQUESTPENDING, {
    variables: {
      company_id: companyId,
    },
  })

  const { data: staffs, loading: loadingStaff } = useQuery<{
    company_staff: {
      id: string
      name: string
    }[]
  }>(GETCOMPANYSTAFF, {
    variables: {
      company_id: companyId,
    },
  })

  const { data: agents, loading: loadingAgents } = useQuery<{
    agents: AgentType[]
  }>(GETALLAGENTS, {
    variables: {
      company_id: companyId,
    },
  })

  const handleSearch = (e: any) => {
    setRequestText(e.target.value)
    setActivePage(1)
  }

  const handleBalanceSearch = (e: any) => {
    setBalanceText(e.target.value)
    setActivePage2(1)
  }

  const filterData = (filter: FundsType) => {
    const isTextMatch =
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.branch_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.division_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.role_balance
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_role_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.company_staff_name
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.status
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase()) ||
      filter?.parameter_type
        ?.toLowerCase()
        ?.includes(searchRequestText?.toLowerCase())

    return isTextMatch
  }

  const filterBalance = (filter: BalanceType) => {
    const isTextMatch = filter?.name
      ?.toLowerCase()
      ?.includes(searchBalanceText?.toLowerCase())
    return isTextMatch
  }
  //------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchBalanceText || itemsPerPage ) {
      setCurrentPage(0)
    }
  }, [searchBalanceText, itemsPerPage])

  // const handlePageChangeBa = (page: number) => {
  //   setCurrentPageBa(page)
  // }

  // const startIndexBa = (currentPageBa - 1) * itemsPerPage
  // const endIndexBa = startIndexBa + itemsPerPage
  const totalBallanceItems =
    balances?.positive_balance_view?.filter(filterBalance)

    const endOffset = currentPage + itemsPerPage
    const paginatedBalanceData = totalBallanceItems?.slice(currentPage, endOffset)

  //------------------
  const [currentPageFa, setCurrentPageFa] = useState(1)

  const handlePageChangeFa = (page: number) => {
    setCurrentPageFa(page)
  }

  const startIndex = (currentPageFa - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const totalFundItems = funds?.view_fund_account_details?.filter(filterData)

  const paginatedFundsData = totalFundItems?.slice(startIndex, endIndex)

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  const handleDownload = () => {
    // Check if there is data to download
    if (!paginatedBalanceData || paginatedBalanceData?.length === 0) {
      alert("No data to download.")
      return
    }

    // Define the headers for the CSV file
    const headers = [
      "record_type",
      "name",
      "cash_limit",
      "balance",
      "amount_used",
    ]

    // Transform the data to match the CSV structure
    const transformedData = balances?.positive_balance_view?.map(
      (item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] =
            item[header] !== undefined ? item[header] : "" // Ensure each header has a value
        })
        return {
          ...transformedItem
        }
      }
    )

    // Convert the data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData || [],
    })

    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)

    // Create a download link and trigger download
    const a = document.createElement("a")
    a.href = url
    a.download = `staff-balance-report.csv`
    document.body.appendChild(a)
    a.click()

    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <>
      <CustomHeader title="Account Balances" />
      <div className="w-full rounded-lg lg:col-span-2 overflow-hidden mt-10">
        <div className="bg-black p-3 text-white">
          <div className="flex w-full justify-between">
            <Typography
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="text-lg"
            >
              Account balances
            </Typography>
            <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
              <Tooltip content="Export as CSV">
                <Button
                  onClick={handleDownload}
                  variant="filled"
                  className="capitalize text-sm font-normal flex flex-wrap gap-2"
                  size="sm"
                >
                  <FiDownloadCloud size={20} />
                  <span>Download csv</span>
                </Button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div className="p-3 bg-white rounded-b-xl">
          <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
            <div className="p-3">
              <div className="w-full mr-3">
                <Input
                  crossOrigin="anonymous"
                  label="Search"
                  value={searchBalanceText}
                  onChange={handleBalanceSearch}
                  icon={<i className="fas fa-search text-base"></i>}
                />
              </div>
            </div>
            <table className=" bg-white w-full min-w-max table-auto text-center">
              <thead className="">
                <tr>
                  {[
                    "no",
                    "Account",
                    "Cash limit",
                    "Cash Imprest",
                    "Amount Used",
                    "Imprest Balance",
                    // "Expected Balance",
                    "Account Type",
                  ].map((head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {paginatedBalanceData?.map((item, index: number) => {
                  const isLast = index === paginatedBalanceData?.length - 1
                  const classes = isLast
                    ? "px-4 py-2  text-center"
                    : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                  return (
                    <tr
                      key={index.toString()}
                      className="hover:bg-gray-100 transition-all even:bg-gray-200"
                    >
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {index + 1}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.name}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.cash_limit).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          GHC{" "}
                          {parseInt(item?.cash_imprest).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          GHC{" "}
                          {parseInt(item?.amount_used).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>
                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          GHC{" "}
                          {parseInt(item?.balance).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Typography>
                      </td>

                      {/* <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize"
                        >
                          {parseInt(item?.remaining_cash_balance).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                          
                        </Typography>
                      </td> */}

                      <td className={classes}>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-normal text-xs capitalize "
                        >
                          {item?.record_type === "sub_agents"
                            ? "Agent"
                            : item?.record_type === "company_staff"
                            ? "Staff"
                            : item?.record_type === "broker"
                            ? "Broker"
                            : ""}
                        </Typography>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </CardBody>

          {loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {!paginatedBalanceData && !loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

          {paginatedBalanceData?.length! === 0 && !loadingBalances && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="black" variant="h6">
                No data found
              </Typography>
            </div>
          )}

         

     
        </div>
        {/* {paginatedBalanceData &&
            paginatedBalanceData?.length !== 0 &&
            !loadingBalances && (
              <GeneralPagination
                color="black"
                totalItems={totalBallanceItems?.length!}
                itemsPerPage={itemsPerPage}
                currentPage={currentPageBa}
                onPageChange={handlePageChangeBa}
              />
            )} */}
            {paginatedBalanceData && paginatedBalanceData?.length !== 0 &&  !loadingBalances && (
            <PerPageAndPagination
              total={totalBallanceItems?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
              setPerPage={setItemsPerPage}
              customColor="white"
            />
          )}
      </div>

    </>
  )
}

export default AccountBalances
