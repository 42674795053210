import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../components/headers/CustomHeader"
import {
  Button,
  Card,
  CardBody,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"
import { RiAdminLine } from "react-icons/ri"
import { useQuery } from "@apollo/client"
import { DRIVERAGGREGATE } from "../../GraphQl/queries"
import { DriverAggreateType } from "../../types/query"
import { toast } from "react-toastify"
import { BASEURL, DEFAULTPRIMARYCOLOR } from "../../util"

import GeneralPagination from "../../components/paginations/generalpagination"
import { useSelector } from "react-redux"
import { RootState } from "../../store/store"
import { getColorCode } from "../../colorCodes"

type ResponseType = {
  account_status: string
  address: string
  balance: number
  bank_details: {
    branch: string
    bank_name: string
    account_number: string
  }
  created_by: string
  consent: true
  dob: string
  email: string
  full_name: string
  gender: string
  id: string
  id_card_type: string
  id_number: string
  momo_wallet_details: {
    number: string
    network: string
  }
  created_by_details: {
    id: string
    phone_number: string
    first_name: string
    last_name: string
  }
}

const Invest2InsureOverview = () => {
  const [searchText, setSearchText] = useState("")
  const { data, loading } = useQuery<DriverAggreateType>(DRIVERAGGREGATE)
  const [searching, setSearching] = useState(false)
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [drivers, setDrivers] = useState<ResponseType[]>([])
  const [activePage, setActivePage] = useState(1)
  const PAGE_SIZE = 15

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const fetchData = async () => {
    setSearching(true)
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/today-driver-registrations?start_date=${startDate}&end_date=${endDate}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      )
      const req = await res.json()

      if (res.ok) {
        setDrivers(req?.data)
      }
      setSearching(false)
    } catch (error: any) {
      toast.error(error?.message ?? "Something went wrong. Try again!")
      setSearching(false)
    }
  }

  useEffect(() => {
    // Function to get today's date in "YYYY-MM-DD" format
    const getFormattedDate = (date: any) => {
      const yyyy = date.getFullYear()
      const mm = String(date.getMonth() + 1).padStart(2, "0") // January is 0!
      const dd = String(date.getDate()).padStart(2, "0")
      return `${yyyy}-${mm}-${dd}`
    }

    // Get today's date
    const today = new Date()
    const formattedToday = getFormattedDate(today)

    // Get tomorrow's date
    const tomorrow = new Date(today)
    tomorrow.setDate(tomorrow.getDate() + 1)
    const formattedTomorrow = getFormattedDate(tomorrow)

    // Set the state
    setStartDate(formattedToday)
    setEndDate(formattedTomorrow)
  }, [])

  // Call fetchData with today's and tomorrow's dates
  useEffect(() => {
    if (startDate && endDate) {
      fetchData()
    }
  }, [startDate, endDate])

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
    setActivePage(1)
  }

  const filterData = (filter: ResponseType) => {
    const isTextMatch =
      filter?.address?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.dob?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.email?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.full_name?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.gender?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      filter?.momo_wallet_details?.network
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.bank_name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.bank_details?.branch
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.id_card_type?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  // Filtered and paginated data for the current page
  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage] = useState(2)

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage
  const paginatedData = drivers?.filter(filterData).slice(startIndex, endIndex)
 

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [stateUser?.colors, userData])

  return (
    <>
      <CustomHeader title="Overview" />

      <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-2 md:lg:gap-4 lg:gap-5">
        <Card className="bg-brand-success rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.total_drivers?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total drivers
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
        <Card className="bg-brand-purple rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.active_drivers?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                active drivers
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
        <Card className="bg-brand-danger rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.inactive_drivers?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                Incomplete driver registration
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
        <Card className="bg-brand-info rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  "GH₵" +
                  (
                    data?.total_collections?.aggregate?.sum?.amount ?? 0
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total collection
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
        <Card className="bg-brand-green rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  "GH₵" +
                  (
                    data?.total_withdrawals?.aggregate?.sum?.amount ?? 0
                  ).toLocaleString("en-US", {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total withdrawals
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
        <Card className="bg-brand-red rounded-lg rounded-tr-[40px] rounded-bl-[50px] shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
          <div className="flex flex-row justify-between">
            <div>
              <Typography className="text-2xl font-bold text-white capitalize tracking-wider">
                {loading ? (
                  <Spinner className="mb-2 text-white" />
                ) : (
                  data?.total_drivers_policies?.aggregate?.count
                )}
              </Typography>
              <Typography className="text-sm uppercase font-normal text-white">
                total drivers policies
              </Typography>
            </div>
            <RiAdminLine size={28} color="#cbcbcb" />
          </div>
        </Card>
      </div>

      <div className="mt-10 flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3 rounded-lg">
        <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
          <div>
            <div className="relative flex w-full max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                From
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
              />
            </div>
          </div>
          <div>
            <div className="relative flex w-full max-w-[24rem]">
              <Button
                ripple={false}
                variant="text"
                color="blue-gray"
                className="flex h-10 items-center gap-2 rounded-r-none border border-r-0 border-blue-gray-200 bg-blue-gray-500/10 pl-3"
              >
                {" "}
                To
              </Button>
              <Input
                crossOrigin="anonymous"
                type="date"
                placeholder="Mobile Number"
                className="rounded-l-none !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }}
                size="md"
                containerProps={{
                  className: "min-w-0",
                }}
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
          </div>

          <Button
            onClick={() => fetchData()}
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-xs font-normal w-1/2 flex items-center justify-center"
            size="md"
          >
            {searching ? <Spinner className="w-4 h-4" /> : "Search"}
          </Button>
        </div>
      </div>

      <div className="mt-5">
        <CardBody className="shadow-sm overflow-x-scroll px-0 py-0  rounded-3xl">
          <div className="bg-black pt-5">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              List of All Today's drivers Registrations{" "}
              {drivers && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {drivers?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
            </div>
          </div>

          <table className="w-full bg-white table-auto">
            <thead className="">
              <tr>
                {[
                  "driver's name",
                  "email",
                  "balance",
                  "momo details",
                  "bank details",
                ].map((head) => (
                  <th
                    key={head}
                    className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                  >
                    <Typography
                      color="black"
                      className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {paginatedData?.map((item, index: number) => {
                const isLast = index === paginatedData?.length - 1
                const classes = isLast
                  ? "px-2 py-3  text-center"
                  : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                return (
                  <tr
                    key={index.toString()}
                    className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                  >
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        {item?.full_name}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs"
                      >
                        {item?.email}
                      </Typography>
                    </td>

                    <td className={classes}>
                      <Typography
                        variant="small"
                        color="blue-gray"
                        className="font-normal text-xs capitalize"
                      >
                        GHS
                        {(item?.balance ?? 0).toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col gap-2 ">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Phone number:{" "}
                          <span className="font-normal">
                            {item?.momo_wallet_details?.number}
                          </span>
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Network:{" "}
                          <span className="font-normal">
                            {item?.momo_wallet_details?.network}
                          </span>
                        </Typography>
                      </div>
                    </td>
                    <td className={classes}>
                      <div className="flex flex-col gap-2 text-left">
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Bank name:{" "}
                          <span className="font-normal">
                            {item?.bank_details?.bank_name}
                          </span>
                        </Typography>
                        <Typography
                          variant="small"
                          color="blue-gray"
                          className="font-bold text-xs capitalize "
                        >
                          Branch:{" "}
                          <span className="font-normal">
                            {item?.bank_details?.branch}
                          </span>
                        </Typography>
                      </div>
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
        </CardBody>
        {searching && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        )}

        {drivers?.length! === 0 && !searching && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {/* Paginator */}
        {paginatedData?.length !== 0 && (
          <GeneralPagination
            totalItems={drivers?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />

        )}
      </div>
    </>
  )
}

export default Invest2InsureOverview
