import {
  Button,
  CardBody,
  CardHeader,
  Card,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState } from "react"
import Papa from "papaparse"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { toast } from "react-toastify"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {BiSearch } from "react-icons/bi"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import moment from "moment"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"
import { FiDownloadCloud } from "react-icons/fi"

type ResponseType = {
  "Transaction Ref": string
  "Transaction Id": string
  "Transaction Date": string
  "Policy Number": string
  "Payment Date":string
  Policyholder: string
  Payee: string
  "Class/Subclass": string
  "Basic Premium": number
  "Commission Rate": string
  "Gross Commission": number
  "Tax Amount": number
  "Net Commission": number
  RiskID: string
}
const PaidCommisionss = () => {
  const [searchText, setSearchText] = useState("")
  const [loading, setLoading] = useState(true)
  const [totalAmount,setTotalAmount]=useState(0);
  const [commissions, setCommissions] = useState<ResponseType[]>([])
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [selectedThick, setSelectedThick] = useState<ResponseType[] | []>([])

  const {
    companyId,
 
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    if(value){
      const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
      setStartDate(formattedValue)

    }
    else{
      setStartDate("")
    }
    
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    if(value){
      const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
      setEndDate(formattedValue)
    }
    else{
      setEndDate("")
    }
  }




  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const filterData = (admin: ResponseType) => {
    const date = moment(admin["Transaction Date"]).format("LLL")
    const isTextMatch =
      admin?.["Transaction Ref"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Transaction Id"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Policy Number"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Gross Commission"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Tax Amount"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Basic Premium"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Net Commission"]
        .toString()
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.RiskID?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.Payee?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.["Policyholder"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      admin?.["Class/Subclass"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      date?.toLowerCase()?.includes(searchText?.toLowerCase()) ||
      admin?.["Commission Rate"]
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const fetchPaidComissions = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/get-paid-commissions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            usertype: DASHBOARD_USERTYPE.company,
            company_id: companyId,
            commission_type: "BDC",
          }),
        }
      )
      const req = await res.json()
      if (res.ok) {
        if (req?.error === 404) {
          setCommissions([])
        } else {
          setCommissions(req?.data)
          setTotalAmount(req?.total_amount ?? 0)
        }
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)

      toast.error("Failed to fetch commissions. Refresh to try again")
    }
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])


  const normalizeDate = (date: Date | string | null | undefined): Date | null => {
    if (!date) return null; // Return null when date is cleared
    const normalizedDate = new Date(date);
    normalizedDate.setHours(0, 0, 0, 0); // Reset time to midnight
    return normalizedDate;
  };
  
  



  const totalItems = commissions?.filter(filterData)?.filter((item: any) => {
    const from = normalizeDate(startDate);
    const to = normalizeDate(endDate);
    const dateCreated = normalizeDate(item["Transaction Date"]);
  
    if (from && to) {
      return dateCreated && dateCreated >= from && dateCreated <= to;
    } else if (from) {
      return dateCreated && dateCreated >= from;
    } else if (to) {
      return dateCreated && dateCreated <= to;
    } else {
      return true; // If both startDate and endDate are cleared, return all items
    }
  });
  
  
  


  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  const [selectedRows, setSelectedRows] = useState<string[]>([])

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedData) {
      const allRows = paginatedData
        ?.filter((p: any) => p?.total_premium_arrears === 0)
        .map((item: any) => item.payable_commission_id)
      setSelectedRows(allRows)
      setSelectedThick(paginatedData)
    } else {
      setSelectedRows([])
      setSelectedThick([])
    }
  }

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    row: ResponseType
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, row?.["Transaction Id"]])
      setSelectedThick([...selectedThick, row])
    } else {
      setSelectedRows(
        selectedRows.filter((id) => id !== row?.["Transaction Id"])
      )
      setSelectedThick(
        selectedThick.filter(
          (obj) => obj["Transaction Id"] !== row["Transaction Id"]
        )
      )
    }
  }


  useEffect(() => {
    if (!startDate && !endDate) {
      fetchPaidComissions();
    }
  }, [startDate, endDate]);


  const reset =() =>{
    setStartDate("");
    setEndDate("");
    fetchPaidComissions()
  }
  

  const handlePrint = () => {
    if (paginatedData.length === 0) {
      window.alert("No data to print")
      return
    }
    // Create a new window for printing
    const printWindow = window.open("", "_blank")

    // Construct the content to be printed
    const printContent = `
      <html>
        <head>
          <title>Collection of Paid Commissions</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              padding: 20px;
            }
            h1 {
              text-align: center;
              font-size: 24px;
              margin-bottom: 20px;
            }
            table {
              width: 100%;
              border-collapse: collapse;
              margin-bottom: 20px;
            }
            th, td {
              border: 1px solid #dddddd;
              text-align: left;
              padding: 10px;
            }
            th {
              background-color: #f2f2f2;
              font-weight: bold;
            }
          </style>
        </head>
        <body>
          <h1>Paid Commissions</h1>
          <table>
            <thead>
              <tr>
                <th>Transaction Ref</th>
                <th>Policy Number</th>
                <th>Payee</th>
                <th>Class/Subclass</th>
                <th>Basic Premium</th>
                <th>Commission Rate</th>
                <th>Gross Commission</th>
                <th>Tax</th>
                <th>Net Commission</th>
                 <th>Transaction Date</th>
              </tr>
            </thead>
            <tbody>
             ${paginatedData
               .map(
                 (rowData) => `<tr>
                <td>${rowData["Transaction Ref"]}</td>
                <td>${rowData["Policy Number"]}</td>
                <td>${rowData["Policyholder"]}</td>
                <td>${rowData["Class/Subclass"]}</td>
                <td>GHS ${(
                  (rowData?.["Basic Premium"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>${rowData["Commission Rate"]}%</td>
                <td>GHS ${(
                  (rowData?.["Gross Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Tax Amount"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
                <td>GHS ${(
                  (rowData?.["Net Commission"] as any) ?? 0
                ).toLocaleString("en-US", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}</td>
             <td>${moment(rowData["Transaction Date"]).format("LLL")}</td>
              </tr>
              `
               )
               .join(" ")}
            </tbody>
          </table>
        </body>
      </html>
    `

    // Write the content to the new window
    printWindow!.document.write(printContent)

    // Close the document after printing
    printWindow!.document.close()

    // Print the window
    printWindow!.print()
  }

  const handleDownload = () => {
    // Check if there is data to download
    if (totalItems?.length === 0) {
      alert("No data to download.")
      return
    }
    if (selectedThick.length > 0) {
      const type = selectedThick?.map((item: any) => ({
        transaction_ref: item["Transaction Ref"],
        policy_number: item["Policy Number"],
        payee: item["Payee"],
        class_subclass: item["Class/Subclass"],
        premium:`GHS ${(
          parseFloat(item["Basic Premium"] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        rate:item["Commission Rate"],
        gross_commission: `GHS ${(item["Gross Commission"] ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        total_tax_deductions: `GHS ${(
          parseFloat(item["Tax Amount"] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        net_commission: `GHS ${(
          parseFloat(item['Net Commission'] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        transaction_date: item["Payment Date"],
      }))
      const firstItem = type[0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"pending-commissions"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    
    } else {
      const type = totalItems?.map(
        (item: any) => ({
          transaction_ref: item["Transaction Ref"],
        policy_number: item["Policy Number"],
        payee: item["Payee"],
        class_subclass: item["Class/Subclass"],
        premium:`GHS ${(
          parseFloat(item["Basic Premium"] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        rate:item["Commission Rate"],
        gross_commission: `GHS ${(item["Gross Commission"] ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        total_tax_deductions: `GHS ${(
          parseFloat(item["Tax Amount"] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        net_commission: `GHS ${(
          parseFloat(item['Net Commission'] as any) ?? 0
        ).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        transaction_date: item["Payment Date"],
        })
      )
      const firstItem = type[0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"paid-commissions"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    }
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  return (
    <>
      <CustomHeader title="Paid commssions" />
      <div className="mb-5 w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4 lg:gap-5">
  <Card className="bg-brand-success rounded-lg shadow-sm px-5 py-6 hover:shadow-md hover:cursor-pointer">
    <div className="flex flex-row justify-between text-center">
      <div>
        <Typography className="text-3xl font-bold text-white capitalize tracking-wider">
        {"GH₵ " +
        (totalAmount).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}

        </Typography>
        <Typography className="text-sm uppercase font-normal text-white">
          Total Paid Commission
        </Typography>
      </div>
    </div>
  </Card>
</div>

      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Total paid commissions
              {commissions && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {commissions?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="flex flex-col md:flex-row lg:flex-row gap-3 bg-white w-full">
            {/* <div className="w-full">
              <Input
                crossOrigin="anonymous"
                label="Search"
                value={searchText}
                onChange={handleSearch}
                icon={<i className="fas fa-search text-base"></i>}
              />
            </div> */}
            <CardHeader
              floated={false}
              shadow={false}
              className="rounded-none w-full"
            >
              <div className="w-full  flex flex-col gap-y-4 ">
                <div className="flex  justify-end">
                <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row">
                  <Button
                    onClick={handlePrint}
                    size="sm"
                    color="green"
                    className=" px-10 text-[1.0rem]"
                  >
                    Print
                  </Button>
                </div>
                <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row pl-10">
                  <Tooltip content="Export as CSV">
                    <Button
                      onClick={handleDownload}
                      variant="filled"
                      className="capitalize text-sm font-normal flex flex-wrap gap-2"
                      size="sm"
                    >
                      <FiDownloadCloud size={20} />
                      <span>Download csv</span>
                    </Button>
                  </Tooltip>
                </div>

                </div>

                <div className="">
                  <div className="p-3 flex gap-x-4 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                    <div className="w-full ">
                      <Input
                        crossOrigin="anonymous"
                        value={searchText}
                        onChange={handleSearch}
                        label="Search"
                        icon={<BiSearch className="h-5 w-5" />}
                      />
                    </div>

                    <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                      <div className="flex items-center space-x-2">
                        <label className="font-semibold text-gray-700">
                          From
                        </label>
                        <Input
                          type="datetime-local"
                          value={startDate.slice(0, -1)} // Remove 'Z' for input value
                          onChange={handleStartDateChange}
                          size="md"
                          className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="flex items-center space-x-2">
                        <label className="font-semibold text-gray-700">
                          To
                        </label>
                        <Input
                          type="datetime-local"
                          value={endDate.slice(0, -1)} // Remove 'Z' for input value
                          onChange={handleEndDateChange}
                          size="md"
                          className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                          labelProps={{
                            className: "before:content-none after:content-none",
                          }}
                        />
                      </div>
                      <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row">
                  <Button
                    onClick={reset}
                    size="sm"
                    color="blue"
                    className=" px-10 text-[1.0rem]"
                  >
                    Reset
                  </Button>
                </div>
                    </div>
                  </div>
                </div>
              </div>
            </CardHeader>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              {/* //min-w-max  */}
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky  top-0">
                  <tr>
                    {[
                      "Transaction Ref",
                      "Provider transaction id",
                      "Policy No.",
                      "Class/Subclass",
                      "Risk ID",
                      "Premium",
                      "Rate",
                      "Gross Commission",
                      "Tax",
                      "Net Commission",
                      "Payee",
                      "Payment Date",
                      "Transaction Date",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.["Policy Number"] + index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer h-[4.7rem] "
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Ref"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item["Transaction Id"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.["Policy Number"]}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.["Class/Subclass"]}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.RiskID}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS {item?.["Basic Premium"]}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.["Commission Rate"]}%
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Gross Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Tax Amount"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS
                            {(
                              (item?.["Net Commission"] as any) ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs"
                          >
                            {item?.Payee}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.["Payment Date"]).format("LLL")}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.["Transaction Date"]).format("LLL")}
                          </Typography>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !loading && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {/* Paginator */}
        {paginatedData && paginatedData?.length !== 0 && !loading && (
          // <GeneralPagination
          //   totalItems={totalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />
          <PerPageAndPagination
          customColor="white"
          total={totalItems?.length!}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={itemsPerPage}
          setPerPage={setItemsPerPage}
        />
        )}
      </div>
    </>
  )
}

export default PaidCommisionss
