import React, { useEffect, useState } from "react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import {
  Button,
  CardBody,
  Input,
  Spinner,
  Typography,
} from "@material-tailwind/react"
import { BiPlus } from "react-icons/bi"

import { SubmitHandler, useForm } from "react-hook-form"
import { useMutation, useQuery } from "@apollo/client"
import {
  GET_COVER_TYPES,
  GET_LOADINGS_RATES,
  GET_SUB_CLASS,

} from "../../../GraphQl/queries"
import { toast } from "react-toastify"
import {

  UPDATE_LOADINGS_RATES,
} from "../../../GraphQl/mutations"

import { DEFAULTPRIMARYCOLOR } from "../../../util"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { DriverModal } from "../../../components/modal/DriverModal"
import ViewCoverTypes from "../discounts/viewCoverTypes"
import { getColorCode } from "../../../colorCodes"
import WhitePaginateComponent from "../../../components/paginations/whiteBackgroundPagination"

type FormType = {
  class: string
  subclass: string
  cover: string
  rate: string
  intermediary: string
  description: string
}

type LoadingRateType = {
  subclass: {
    id: string
    name: string
    __typename: string
  }
  status: string
  rate: string
  id: string
  covertype: {
    id: string
    name: string
    __typename: string
  }
  company_loading: {
    id: string
    loading: {
      id: string
      name: string
      businessClassByBusinessClass: {
        id: string
        name: string
        __typename: string
      }
      __typename: string
    }
    __typename: string
  }
  __typename: string
}

const ManagePolicyLoadings = () => {
  const getStorageLoadings = localStorage.getItem("loading")
  const [searchText, setSearchText] = useState("")
  const [isEditing, setIsEditing] = useState(false)
  const [editId, setEditId] = useState<any>()
  const [selectedCover, setSelectedCover] = useState("")
  const [isDisabled, setIsDisabled] = useState(false)
  const [editItem, setEditItem] = useState<any>(null)
  const [selectedLoading, setLoading] = useState<{
    id: string
    name: string
    description: string
    bus_name: string
    bus_id: string
  }>()

  const [open, setOpen] = useState(false)

  const {
    companyId,

    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { isSubmitting },
  } = useForm<FormType>()
  // console.log(selectedLoading)
  const watchSubClass = watch("subclass")

 
  const { data, loading, refetch } = useQuery<{
    loading_rates: LoadingRateType[]
  }>(GET_LOADINGS_RATES, {
    variables: {
      id: selectedLoading?.id,
    },
  })



  const {
    data: subclasses,
    loading: loadingSubClass,
  } = useQuery<{
    subclass: {
      id: string
      name: string
    }[]
  }>(GET_SUB_CLASS, {
    variables: {
      id: selectedLoading?.bus_id,
    },
  })

 

  const {
    data: coverTypeData,
    loading: loadingCoverType,
    refetch: refetchCoverTypes,
  } = useQuery<{
    company_subclass_covertypes: {
      covertypes: {
        id: string
        name: string
        description: string
      }[]
      __typename: string
    }[]
  }>(GET_COVER_TYPES, {
    variables: {
      company_id: companyId,
      sub_class_id: watchSubClass,
    },
  })

  // console.log(coverTypeData)

  // const [CreateDiscountRate, { loading: RequestLoading }] = useMutation(
  //   ADDDISCOUNTRATE,
  //   {
  //     onCompleted: (data) => {
  //       toast.success("Discount rate Successfully added")
  //       reset()
  //       refetch()
  //       refetchCoverTypes()
  //     },
  //     onError: (error) =>
  //       toast.error(error?.message ?? "Error creating discount rate"),
  //   }
  // )

  const [Updateloading] = useMutation(UPDATE_LOADINGS_RATES, {
    onCompleted: (data) => {
      toast.success("Loading rate Updated Successfully ")
      reset()
      refetch()
      refetch()
      refetchCoverTypes()
    },
    onError: (error) =>
      toast.error(error?.message ?? "Error updating loading rate"),
  })

  // const [DeleteDiscountRate] = useMutation(DELETEDISCOUNTRATE, {
  //   onCompleted: (data) => {
  //     toast.success("Discount rate deleted successfully")
  //     refetch()
  //   },
  //   onError: (error) =>
  //     toast.error(error?.message ?? "Error deleting discount rate"),
  // })

  const filterData = (filter: LoadingRateType) => {
    const isTextMatch =
      filter?.subclass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.company_loading?.loading?.businessClassByBusinessClass?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.covertype?.name
        ?.toLowerCase()
        ?.includes(searchText?.toLowerCase()) ||
      filter?.rate?.toLowerCase()?.includes(searchText?.toLowerCase())
    return isTextMatch
  }

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText) {
      setCurrentPage(0)
    }
  }, [searchText])



  const totalItems = data?.loading_rates?.filter(filterData)
  // const paginatedData = totalItems?.slice(startIndex, endIndex)

  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)



  const handleEdit = (item: any) => {
   
    setIsEditing(true)
    setEditId(item?.id)
    setValue("subclass", item?.subclass?.id || "")
    setValue("rate", item?.rate || "0")
    setSelectedCover(item?.covertype?.id)
    setEditItem(item)
  }

 

  // Filtered and paginated data for the current page

  const onSubmit: SubmitHandler<FormType> = async (data) => {
   
    if (isEditing && editId) {
      Updateloading({
        variables: {
          rate: data.rate,
          status: "active",
          sub_class_id: data.subclass,
          covertype_id: data.cover,
          id: editId,
        },
        onCompleted: () => {
          toast.success("Loading rates updated successfully")
          reset()
          setIsDisabled(false)
          setIsEditing(false)
          setSelectedCover("")

          setEditId(null)
          refetch()
          refetchCoverTypes()
        },
        onError: (error: any) =>
          toast.error(error?.message ?? "Error updating loading"),
      })
      return
    }
    try {

      const res = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/add-rate-to-loading`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            rate: data.rate,
            status: "active",
            sub_class_id: data.subclass,
            covertype_id: data.cover,
            company_loading_id: selectedLoading?.id,
          }),
        }
      )

     

      if (!res.ok) {
        const data1 = await res.json()
       
        toast.error("Error creating loading rate")
        return
      }
      const data1 = await res.json()
   
      if (!data1?.success) {
        toast.error(data1?.message)
        return
      }
      reset()
      setSelectedCover("")
      toast.success("Loading rate created Successfully")
      refetch()
    } catch (error) {
      console.log(error)
      toast.error("Error creating loading rate")
    }
  }

  // const deleteDiscountRate = (id: string) => {
  //   DeleteDiscountRate({
  //     variables: {
  //       id,
  //     },
  //   })
  //   try {
  //   } catch (error: any) {
  //     toast.error(error?.message ?? "Error deleting discount rate")
  //   }
  // }

  useEffect(() => {
    if (getStorageLoadings) {
      setLoading(JSON.parse(getStorageLoadings))
      refetch()
    }
  }, [])

  // const userData = localStorage.getItem("user")
  const [colors, setColors] = useState<string[]>()

 

  const rr = (data: any) => {
    console.log(data)
  }

  const userData = stateUser
  // const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  return (
    <>
      <CustomHeader title="Loading rates" />
      <DriverModal
        title={<p className=" text-gray-800 text-[1.2rem]">Rates</p>}
        open={open}
        size={"lg"}
        focus="message"
        onClose={() => setOpen(false)}
        children={
          <ViewCoverTypes cover={[]} onClose={() => setOpen(false)} />
        }
      />

      <div className="my-5">
        <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
          <table className=" bg-white w-full min-w-max table-auto text-left">
            <thead className="">
              <tr>
                {["Class of Business", "Type of Loading", "Description"].map(
                  (head) => (
                    <th
                      key={head}
                      className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                    >
                      <Typography
                        color="black"
                        className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                      >
                        {head}
                      </Typography>
                    </th>
                  )
                )}
              </tr>
            </thead>
            <tbody>
              <tr className="transition-all bg-gray-300">
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedLoading?.bus_name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedLoading?.name}
                  </Typography>
                </td>
                <td className="px-4 py-3 border-b-2 border-blue-gray-50 text-left">
                  <Typography
                    variant="small"
                    color="blue-gray"
                    className="font-normal text-xs capitalize "
                  >
                    {selectedLoading?.description}
                  </Typography>
                </td>
              </tr>
            </tbody>
          </table>
        </CardBody>
      </div>

      <div className="w-full flex flex-col md:flex-row lg:flex-row gap-5">
        <div className=" rounded-lg overflow-hidden w-full  lg:w-3/4">
          <div
            style={{
              background:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className=" p-3"
          >
            <Typography className="text-xl text-white">
              {isEditing ? "Update" : "Create"} Loading rates
            </Typography>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="p-3 capitalize bg-white rounded-b-xl"
          >
            <div className="mt-5 flex flex-col items-end justify-end">
              <div className="w-full max-w-2xl ">
                <div className="mb-6 flex flex-col gap-2">

                  {/* <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Class of Business
                    </Typography>

                    <select
                      id="Class"
                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("class", { required: true })}
                    >
                      <option>
                        {loadingClasses ? "Loading..." : "Select class"}
                      </option>
                      {classes?.business_classes?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Subclass
                    </Typography>
                    <select
                      id="subclass"
                      disabled={isDisabled}

                      className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      {...register("subclass", { required: true })}
                    >
                      <option>
                        {loadingSubClass ? "Loading..." : "Select subclass"}
                      </option>
                      {subclasses?.subclass?.map((data) => (
                        <option label={data?.name} value={data?.id}>
                          {data?.name}
                        </option>
                      ))}
                      <option
                        value={"00000000-0000-0000-0000-000000000000"}
                        label="All"
                      >
                        All
                      </option>
                    </select>
                  </div>
                  <div>
                    <div className="w-full my-2 flex items-center">
                      <Typography
                        variant="h6"
                        color="blue-gray"
                        className="mb-2 font-semibold text-[14px] w-1/3"
                      >
                        Cover Type
                      </Typography>
                      <div className="w-full my-2 flex flex-col items-start">
                        <select

                          disabled={isDisabled}
                          id="covertypes"
                          className="h-11 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          {...register("cover", { required: true })}
                          value={selectedCover || ""}
                          onChange={(e) => setSelectedCover(e.target.value)}
                        >
                          {!selectedCover && (
                            <option value="" disabled>
                              {loadingCoverType ? "Loading..." : "Select covertype"}
                            </option>
                          )}
                          {coverTypeData?.company_subclass_covertypes[0]?.covertypes?.map((data) => (
                            <option key={data?.id} value={data?.id}>
                              {data?.name}
                            </option>
                          ))}
                        </select>
                        {/* {selectedCover && (
                          <p className=" pt-2 pl-2 text-[0.9rem]">
                            Selected: {selectedCover}
                          </p>
                        )} */}
                      </div>
                    </div>
                  </div>
                  <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      rate
                    </Typography>
                    <Input
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("rate", { required: true })}
                    />
                  </div>

                  {/* <div className="w-full my-2 flex items-center">
                    <Typography
                      variant="h6"
                      color="blue-gray"
                      className="mb-2 font-semibold text-[14px] w-1/3"
                    >
                      Description
                    </Typography>
                    <Textarea
                      size="lg"
                      className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                      {...register("description", { required: true })}
                    />
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex items-end justify-end gap-x-2">
              {selectedCover && isEditing && (
                <Button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault()
                    setSelectedCover("")
                    setIsDisabled(false)
                    setIsEditing(false)
                    setEditId(null)
                    reset()
                  }}
                  size="sm"
                  className="capitalize flex flex-row items-centerfont-normal bg-red-300"

                >
                  reset
                </Button>
              )}
              <Button
                type="submit"
                size="sm"
                className="capitalize flex flex-row items-centerfont-normal"
                style={{
                  background:
                    colors?.length! > 1
                      ? `${colors![0]?.toLowerCase()}`
                      : `${DEFAULTPRIMARYCOLOR}`,
                }}
              >
                <BiPlus size={20} />
                {isSubmitting
                  ? "Creating..."
                  : `${isEditing ? "Update" : "Create"} Loading Rate`}
              </Button>
            </div>
          </form>
        </div>

        {/*  */}
        <div className="w-full rounded-lg lg:col-span-2 overflow-hidden">
          <div className="bg-black p-3 text-white">
            <Typography className="text-lg">
              List of all {selectedLoading?.name} Rates
            </Typography>
          </div>
          <div className="p-3 bg-white rounded-b-xl">
            <CardBody className="shadow-none overflow-x-scroll px-0 py-0  rounded-none">
              <div className="p-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
              </div>
              <table className=" bg-white w-full min-w-max table-auto text-left">
                <thead className="">
                  <tr>
                    {["no", "class", "Subclass", "covertype", "rate", ""].map(
                      (head) => (
                        <th
                          key={head}
                          className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                        >
                          <Typography
                            color="black"
                            className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                          >
                            {head}
                          </Typography>
                        </th>
                      )
                    )}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-4 py-2  text-left"
                      : "px-4 py-2 border-b-2 border-blue-gray-50 text-left"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all even:bg-gray-200"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {index + 1}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {
                              item?.company_loading?.loading
                                ?.businessClassByBusinessClass?.name
                            }
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {`${item?.subclass ? item?.subclass?.name : "N/A"}`}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.covertype?.name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.rate}%
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                setIsDisabled(true)
                                handleEdit(item)
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Edit
                            </Button>
                            {/* <IconButton
                              onClick={() => deleteCommission(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton> */}
                          </div>
                        </td>

                        {/* <td className={classes}>
                          <div className="flex flex-row items-center gap-x-2">
                            <Button
                              onClick={() => {
                                setOpen(true)
                                setSelectedItem(item?.covertypes === null ? []: item?.covertypes )
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-blue-400 w-fit px-5"
                            >
                              View Rates
                            </Button>
                            <IconButton
                              onClick={() => {}}
                              color="green"
                              size="md"
                              variant="filled"
                            >
                              <BiEdit className=" text-[1.05rem]" />
                            </IconButton>
                            <IconButton
                              onClick={() => deleteDiscountRate(item?.id)}
                              color="red"
                              size="sm"
                            >
                              <BiTrash />
                            </IconButton>
                          </div>
                        </td> */}
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </CardBody>

            {loading && (
              <div className="mt-10 flex items-center justify-center">
                <Spinner className="w-1o h-10" />
              </div>
            )}

            {!paginatedData && !loading && (
              <div className="mt-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}
            {paginatedData?.length! === 0 && !loading && (
              <div className="my-10 flex items-center justify-center">
                <Typography color="black" variant="h6">
                  No data found
                </Typography>
              </div>
            )}

            {/* Paginator */}
            {paginatedData && paginatedData?.length! !== 0 && !loading && (
              // <GeneralPagination
              //   color="black"
              //   totalItems={totalItems?.length!}
              //   itemsPerPage={itemsPerPage}
              //   currentPage={currentPage}
              //   onPageChange={handlePageChange}
              // />

              <WhitePaginateComponent
                totalPages={totalItems?.length!}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                perPage={itemsPerPage}
              />
            )}
          </div>
        </div>
      </div>
      <div className="h-20" />
    </>
  )
}

export default ManagePolicyLoadings
