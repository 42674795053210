import {
  Button,
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState, useMemo } from "react"
import { CustomDeleteModal, DetailDrawer } from "../../../components"
import { DEFAULTPRIMARYCOLOR } from "../../../util"

import { UpdateType } from "../../../types/admin"
import { toast } from "react-toastify"
import { UserType } from "../../../types/user"
import { useForm } from "react-hook-form"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GETPAYMENTAPPROVALS, GETNONCONSOLIDATEDPAYMENTREQUISITIONSTATUS } from "../../../GraphQl/queries"
import moment from "moment"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

// Define TypeScript types for the query response

interface AccountDetails {
  code: string
  branch: string
  bank_name: string
  acct_number: string
  account_name: string
}

interface PaymentRequisitionDetail {
  account_details: string | AccountDetails // This is a JSON string, you might want to parse it
  created_at: string
  policy_number: string
  risk_id: string
  business_class_name: string
  sub_class_name: string
  id: string
  insurance_company_id: string
  payee_details: string
  provider_transaction_id: string
  amount_requisitioned: number
  payee_id: string
  payee_name: string
  payee_number: string
  payee_type: string
  payment_stage: string
  policy_id: string
  status: string
  rate: string
  reference: string
  reference_amount: number
  transaction_type: string
  __typename: string
}

interface DataItem {
  id: string;
  status: string;
  provider_transaction_id: string;
}

interface ResolvingState {
  [key: string]: boolean;
}

const PaymentApproval = () => {
  const history = useHistory()
  const [user, setUser] = useState<UserType>()
  const [requistionText, setRequistionSearch] = useState("")

  const [loading, setLoading] = useState(false)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow] = useState<UpdateType>()
  const [showUpdateModal] = useState(false)
  const [resolving, setResolving] = useState<ResolvingState>({}); 


  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,

    user: stateUser,
    token: stateToken,
  } = useSelector((state: RootState) => state.auth)
  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    }
  }, [showUpdateModal])

  const { setValue } = useForm<UpdateType>({
    defaultValues: defaultValues,
  })

  const { data: requisition, loading: loadingRequistion, refetch } = useQuery<{
    view_payment_requistion_details: PaymentRequisitionDetail[]
  }>(GETNONCONSOLIDATEDPAYMENTREQUISITIONSTATUS, {
    variables: {
      id: companyId,
    },
  })

  //  console.log(requisition)

  const requisitionConvert = requisition?.view_payment_requistion_details?.map(
    (item) => {
      return {
        ...item,
        account_details: JSON.parse(item.account_details as string),
      }
    }
  )

  const handleResolvePayment = async (trasaction_number: string) => {
    try {
      setResolving((prev) => ({ ...prev, [trasaction_number]: true }));
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/resolve-processing-payment?transaction_number=${trasaction_number || ""}&company_id=${companyId || ""}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
        }
      )

      const req = await response.json()
      if (response.ok) {
        refetch()
        toast.success("Resolved Successfully")
      } else {
        toast.error(req?.msg || "Error resolving")
      }
    } catch (error) {
      toast.error("Error resolving")
    } finally {
      setResolving((prev) => ({ ...prev, [trasaction_number]: false }));
    }
  }


  // console.log(requisitionConvert)
  const handleRequistionSerach = (e: any) => {
    setRequistionSearch(e.target.value)
  }

  const filterData = (admin: PaymentRequisitionDetail) => {
    const date = moment(admin?.created_at).format("LLL")
    if (!requistionText) {
      return true
    }

    return (
      admin?.provider_transaction_id
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      (admin?.account_details as AccountDetails)?.account_name
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      (admin?.account_details as AccountDetails)?.bank_name
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      (admin?.account_details as AccountDetails)?.branch
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.policy_number
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.risk_id.toLowerCase().includes(requistionText.toLowerCase()) ||
      date?.toLowerCase().includes(requistionText.toLowerCase()) ||
      admin?.business_class_name
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.sub_class_name
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.payee_name.toLowerCase().includes(requistionText.toLowerCase()) ||
      admin?.payee_number
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.payee_type.toLowerCase().includes(requistionText.toLowerCase()) ||
      admin?.amount_requisitioned
        .toString()
        .toLowerCase()
        .includes(requistionText.toLowerCase()) ||
      admin?.created_at.toLowerCase().includes(requistionText.toLowerCase()) ||
      admin?.status.toLowerCase().includes(requistionText.toLowerCase())
    )
  }
  //---------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (requistionText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [requistionText, startDate, endDate, itemsPerPage])

  const totalItems = requisitionConvert
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0)
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.created_at)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  // const paginatedRequisitionData = totalItems?.slice(startIndex, endIndex)

  const endOffset = currentPage + itemsPerPage
  const paginatedRequisitionData = totalItems?.slice(currentPage, endOffset)

  // console.log(paginatedRequisitionData)

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedRequisitionData?.length === 0) {
      alert("No data to download.")
      return
    }

    if (requistionText || startDate || endDate) {
      const type = totalItems?.map((item: PaymentRequisitionDetail) => ({
        "Transaction Number": item?.provider_transaction_id,
        "Payment Transaction Type": item?.transaction_type,
        "Policy Number": item?.policy_number,
        "Risk Id": item?.risk_id,
        "Business Class/Subclass": `${item?.business_class_name} / ${item?.sub_class_name}`,
        "Requisition Details": `Amount requisitioned: ${item?.amount_requisitioned.toLocaleString(
          "en-US",
          {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }
        )}, Initiation Type: ${
          item?.transaction_type
        }, Reference amount.: ${item?.reference_amount.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        "Payee Details": `Payee Name: ${item?.payee_name}, Payee Number: ${item?.payee_number}, Payee Type: ${item?.payee_type}`,
        "Account Details": `Account Name: ${
          (item?.account_details as AccountDetails)?.account_name
        }, Bank Name: ${
          (item?.account_details as AccountDetails)?.bank_name
        }, Bank Branch: ${(item?.account_details as AccountDetails)?.branch}`,
        Amount: item?.amount_requisitioned.toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        "Created at": moment(item?.created_at).format("LLL"),
        Status: item?.status,
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"FT-approvals"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    }
    // Define custom headers based on the selected filter

    const type = requisitionConvert?.map((item: PaymentRequisitionDetail) => ({
      "Transaction Number": item?.provider_transaction_id,
      "Payment Transaction Type": item?.transaction_type,
      "Policy Number": item?.policy_number,
      "Risk Id": item?.risk_id,
      "Business Class/Subclass": `${item?.business_class_name} / ${item?.sub_class_name}`,
      "Requisition Details": `Amount requisitioned: ${item?.amount_requisitioned.toLocaleString(
        "en-US",
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }
      )}, Initiation Type: ${
        item?.transaction_type
      }, Reference amount.: ${item?.reference_amount.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      "Payee Details": `Payee Name: ${item?.payee_name}, Payee Number: ${item?.payee_number}, Payee Type: ${item?.payee_type}`,
      "Account Details": `Account Name: ${
        (item?.account_details as AccountDetails)?.account_name
      }, Bank Name: ${
        (item?.account_details as AccountDetails)?.bank_name
      }, Bank Branch: ${(item?.account_details as AccountDetails)?.branch}`,
      Amount: item?.amount_requisitioned.toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }),
      "Created at": moment(item?.created_at).format("LLL"),
      Status: item?.status,
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"FT-approvals"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  useEffect(() => {
    let storage = localStorage.getItem("user")
    if (storage) {
      setUser(JSON.parse(storage))
    }
  }, [])

  // set default values
  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value)
      })
    }
  }, [defaultValues])

  const handleDelete = async () => {
    setOpenDrawer(false)
    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/delete-admin`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: JSON.stringify({
            admin_id: selectedRow?.admin_id,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        toast.success("Admin deleted successfully")
        SetShowDeleteModal(false)
        if (user?.email === selectedRow?.email) {
          localStorage.clear()
          history?.push("/")
          //()
        }
      } else {
        toast.error(req?.msg)
      }
      setLoading(false)
    } catch (error) {
      toast.error("Error deleting admin")
      setLoading(false)
    }
  }
  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  React.useEffect(() => {
    const data = localStorage.getItem("user")
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])

  return (
    <>
      <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      />

      <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        children={<>{/* <RenderAdminDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Payment status" />

      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of All Payment Order Status{" "}
              {requisitionConvert && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {requisitionConvert?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full">
            <div className=" flex flex-col gap-y-2 bg-white p-3">
              <div className="flex shrink-0 flex-col justify-end gap-2 flex-wrap lg:flex-row pl-3">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={requistionText}
                    onChange={handleRequistionSerach}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
                <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "Transaction Number",
                      "Payment Transaction Type",
                      "Policy Number",
                      "Risk Id",
                      "Business Class/Subclass",
                      "Requisition Details",
                      "Payee Details",
                      "Account Details",
                      "Amount",
                      "Created at",
                      "Status",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedRequisitionData?.map((item, index: number) => {
                    const isLast =
                      index === paginatedRequisitionData?.length - 1
                    const classes = isLast
                      ? "px-2 py-4  text-center"
                      : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.provider_transaction_id}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.transaction_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            {item?.policy_number}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.risk_id}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.business_class_name +
                              " / " +
                              item?.sub_class_name}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Amount requisitioned:{" "}
                            {(item?.amount_requisitioned).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Initiation Type:{" "}
                            <span className="font-normal">
                              {item?.transaction_type}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Reference amount.:{" "}
                            {(item?.reference_amount).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}{" "}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Payee Name: {item?.payee_name}
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Payee Number:{" "}
                            <span className="font-normal">
                              {item?.payee_number}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Payee Type: {item.payee_type}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Account Name:{" "}
                            <span className="font-normal">
                              {item?.account_details?.account_name}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Bank Name :{" "}
                            <span className="font-normal">
                              {item?.account_details?.bank_name}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Bank Branch:{" "}
                            <span className="font-normal">
                              {item?.account_details?.branch}{" "}
                            </span>
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {(item?.amount_requisitioned).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}{" "}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.created_at).format("LLL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <span
                            className={`${
                              item?.status?.toLowerCase() === "completed"
                                ? "text-green-500 text-xs"
                                : item?.status?.toLowerCase() === "processing"
                                ? "text-yellow-700 text-xs"
                                : "text-red-400 text-xs"
                            }`}
                          >
                            {item?.status.toLocaleLowerCase()}
                          </span>
                        </td>
                        <td className={classes}>
                          {item?.status?.toLowerCase() === "processing" && (
                            <Button
                              onClick={(e) => {
                                console.log(item?.provider_transaction_id)
                                handleResolvePayment(item?.provider_transaction_id)
                              }}
                              // onClick={() => { setChosenId(item?.payable_commission_id); setShowConfirmation(true); }}
                              size="sm"
                              disabled={!!resolving[item?.provider_transaction_id]}
                              color="red"
                              className="text-xs font-normal capitalize bg-brand-cyan"
                            >
                             {resolving[item?.provider_transaction_id] ? "Resolving..." : "Resolve"}
                             
                            </Button>
                          )}
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loadingRequistion && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-1o h-10" />
            </div>
          )}
        </CardBody>

        {(!paginatedRequisitionData ||
          paginatedRequisitionData?.length! === 0) &&
          !loadingRequistion && (
            <div className="mt-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}

        {paginatedRequisitionData &&
          paginatedRequisitionData?.length !== 0 &&
          !loadingRequistion && (
            // <GeneralPagination
            //   totalItems={requisition?.view_payment_requistion_details?.length! ?? 0}
            //   itemsPerPage={itemsPerPage}
            //   currentPage={currentPage}
            //   onPageChange={handlePageChange}
            // />

            <PerPageAndPagination
            customColor="white"
              total={requisition?.view_payment_requistion_details?.length!}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPage={itemsPerPage}
              setPerPage={setItemsPerPage}
            />
          )}
      </div>
    </>
  )
}

export default PaymentApproval
