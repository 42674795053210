import {
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
  Button,
} from "@material-tailwind/react"
import React, { useEffect, useState } from "react"
import { DetailDrawer } from "../../../components"
import { DEFAULTPRIMARYCOLOR } from "../../../util"

import { CustomHeader } from "../../../components/headers/CustomHeader"
import { BiSearch } from "react-icons/bi"
import { GETALLPAYABLETAXES } from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

type BusinessClass = {
  name: string
  id: string
}

type RiskClass = {
  id: string
  name: string
}

type SubAgent = {
  id: string
  last_name: string
  first_name: string
}

type CompanyStaff = {
  id: string
  name: string
}

type PolicyRequest = {
  cover_type: string
  amount: number
  businessClassByBusinessClass: BusinessClass
  risk_class: RiskClass
  sub_agent: SubAgent
  company_staff: CompanyStaff
}

type Policy = {
  policy_number: string
  request: PolicyRequest
}
type TaxPayablee = {
  amount: "number"
  rate: "number"
  id: "string"
  created_at: "string"
  commissions_payable: {
    policy: {
      policy_number: "string"
      request: {
        cover_type: "string"
        user_type: "string"
        amount: "string"
        businessClassByBusinessClass: {
          name: "string"
          id: "string"
          __typename: "string"
        }
        risk_class: {
          id: "string"
          name: "string"
          __typename: "string"
        }
        sub_agent: "null"
        company_staff: {
          id: "string"
          phone_number_one: "string"
          name: "string"
          __typename: "string"
        }
        company_tin: {
          tin_number: "string"
          __typename: "string"
        }
        __typename: "string"
      }
      __typename: "string"
    }
    total_tax_deductions: "number"
    commission_amount: "number"
    __typename: "string"
  }
  tax: {
    id: "string"
    name: "string"
    __typename: "string"
  }
  __typename: "tax_payable"
}

export type TaxPayable = {
  amount: number
  rate: number
  id: string
  created_at: string
  commissions_payable: {
    policy: Policy
    total_tax_deductions: number
    commission_amount: number
  }
  tax: {
    id: string
    name: string
  }
}

const PremiumView = () => {
  const [searchText, setSearchText] = useState("")

  const [openDrawer, setOpenDrawer] = React.useState(false)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,

    user: stateUser,
  } = useSelector((state: RootState) => state.auth)

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z"
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z"
    setEndDate(formattedValue)
  }
  // console.log(startDate, endDate)

  const handleSearch = (e: any) => {
    setSearchText(e.target.value)
  }

  const filterData = (admin: any) => {
    const { commissions_payable } = admin
    const { policy } = commissions_payable
    const { request } = policy
    const { company_tin } = request
    const { tin_number } = company_tin
    const { businessClassByBusinessClass } = request
    const { name } = businessClassByBusinessClass
    const { risk_class } = request
    const { cover_type } = request
    const { amount } = request
    const { sub_agent } = request
    const { company_staff } = request
    const { name: staffName } = company_staff ? company_staff : { name: "N/A" }
    const { phone_number_one } = company_staff
      ? company_staff
      : { phone_number_one: "N/A" }
    const { user_type } = request
    const { policy_number } = policy
    const { total_tax_deductions } = commissions_payable
    const { commission_amount } = commissions_payable
    const { tax } = admin
    const { name: taxName } = tax
    const { amount: taxAmount } = admin
    const { rate } = admin
    const { created_at } = admin
    const date = moment(created_at).format("LLL")
    // console.log(date)
    const values = [
      tin_number,
      name,
      risk_class.name,
      cover_type,
      amount,
      sub_agent,
      company_staff,
      staffName,
      phone_number_one,
      user_type,
      policy_number,
      total_tax_deductions,
      commission_amount,
      taxName,
      taxAmount,
      rate,
      created_at,
    ]
    const commission_amount_1 = total_tax_deductions + commission_amount

    const search = values.join(" ").toString().toLowerCase()

    return (
      search.includes(searchText.toLowerCase()) ||
      commission_amount_1
        ?.toString()
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      date?.toString().toLowerCase().includes(searchText.toLowerCase())
    )
  }

  //-----------------------------

  //-----------------------------

  const { data, loading } = useQuery<{
    tax_payable: TaxPayablee[]
  }>(GETALLPAYABLETAXES, {
    variables: {
      company_id: companyId,
    },
  })

  // console.log(data)

  //------------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])

  const totalItems = data?.tax_payable
    ?.filter(filterData)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.created_at)
      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  // const paginatedData = totalItems?.slice(startIndex, endIndex)
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)


  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])

  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    if (searchText || startDate || endDate) {
      const type = totalItems?.map((item: TaxPayablee) => ({
        "Policy Number": item?.commissions_payable?.policy?.policy_number,
        "Payee TIN":
          item?.commissions_payable?.policy?.request?.company_tin.tin_number,
        "Business Class":
          item?.commissions_payable?.policy.request.businessClassByBusinessClass
            ?.name,
        "Risk Class":
          item.commissions_payable?.policy?.request?.risk_class?.name,
        "Cover Type": item?.commissions_payable?.policy?.request?.cover_type,
        "Premium Amount": item?.commissions_payable?.policy.request?.amount,
        "Commission Amount": item?.commissions_payable?.commission_amount,
        "Tax Amount": item?.amount,
        Rate: item?.rate,
        "Created At": moment(item?.created_at).format("LL"),
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"taxes"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    }

    const type = data?.tax_payable?.map((item: TaxPayablee) => ({
      "Policy Number": item?.commissions_payable?.policy?.policy_number,
      "Payee TIN":
        item?.commissions_payable?.policy?.request?.company_tin.tin_number,
      "Business Class":
        item?.commissions_payable?.policy.request.businessClassByBusinessClass
          ?.name,
      "Risk Class": item.commissions_payable?.policy?.request?.risk_class?.name,
      "Cover Type": item?.commissions_payable?.policy?.request?.cover_type,
      "Premium Amount": item?.commissions_payable?.policy.request?.amount,
      "Commission Amount": item?.commissions_payable?.commission_amount,
      "Tax Amount": item?.amount,
      Rate: item?.rate,
      "Created At": moment(item?.created_at).format("LL"),
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"taxes"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  return (
    <>
      <DetailDrawer
        size={350}
        title="detail"
        open={openDrawer}
        children={<>{/* <RenderTaxDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Total Taxes" />

      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold  leading-none opacity-70 capitalize"
            >
              Taxes Record
              {data?.tax_payable && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {data?.tax_payable?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full bg-white p-3">
            <div className="flex flex-col gap-y-2">
              <div className="flex shrink-0 flex-col  justify-end gap-2 sm:flex-row ml-7">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-3">
                <div className="w-full ">
                  <Input
                    crossOrigin="anonymous"
                    value={searchText}
                    onChange={handleSearch}
                    label="Search"
                    icon={<BiSearch className="h-5 w-5" />}
                  />
                </div>
                <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "policy number",
                      "Payee tin",
                      "business class",
                      "subclass",
                      "cover type",
                      "premium amount",
                      "Commission amount",
                      "Tax amount",
                      "rate",
                      "created at",
                      "Payee Details",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item: any, index: number) => {
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "p-2  text-center"
                      : "p-2 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={index}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.commissions_payable?.policy?.policy_number}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {
                              item?.commissions_payable?.policy?.request
                                .company_tin.tin_number
                            }
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {
                              item?.commissions_payable?.policy?.request
                                ?.businessClassByBusinessClass?.name
                            }
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {
                              item?.commissions_payable?.policy?.request
                                ?.risk_class?.name
                            }
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {
                              item?.commissions_payable?.policy?.request
                                ?.cover_type
                            }
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {parseFloat(
                              item?.commissions_payable?.policy?.request
                                ?.amount ?? 0
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {(
                              (Number(
                                item?.commissions_payable?.commission_amount
                              ) || 0) +
                              (Number(
                                item?.commissions_payable?.total_tax_deductions
                              ) || 0)
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            GHS{" "}
                            {parseFloat(item?.amount ?? 0).toLocaleString(
                              "en-US",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.rate} %
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs "
                          >
                            {moment(item?.created_at).format("LL")}
                          </Typography>
                        </td>

                        <td className={classes}>
                          <div className="flex flex-col gap-2 text-left">
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Full name:{" "}
                              <span className="font-normal">
                                {item?.commissions_payable?.policy?.request
                                  ?.company_staff
                                  ? `${item?.commissions_payable?.policy?.request?.company_staff?.name}`
                                  : `${item?.commissions_payable?.policy?.request?.sub_agent?.first_name} ${item?.commissions_payable?.policy?.request?.sub_agent?.last_name}`}
                              </span>
                            </Typography>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              Phone number:{" "}
                              <span className="font-normal">
                                {item?.commissions_payable?.policy?.request
                                  ?.company_staff
                                  ? item?.commissions_payable?.policy?.request
                                      ?.company_staff?.phone_number_one
                                  : item?.commissions_payable?.policy?.request
                                      ?.sub_agent?.phone_number}
                              </span>
                            </Typography>

                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-bold text-xs capitalize "
                            >
                              User type:{" "}
                              <span className="font-normal">
                                {
                                  item?.commissions_payable?.policy.request
                                    ?.user_type
                                }
                              </span>
                            </Typography>
                          </div>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
          {loading && (
            <div className="mt-10 flex items-center justify-center">
              <Spinner className="w-10 h-10" />
            </div>
          )}

          {paginatedData?.length! === 0 && !loading && (
            <div className="my-10 flex items-center justify-center">
              <Typography color="white" variant="h6">
                No data found
              </Typography>
            </div>
          )}
        </CardBody>

        {paginatedData && paginatedData?.length! !== 0 && !loading && (
          // <GeneralPagination
          //   totalItems={totalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />

          <PerPageAndPagination
          customColor="white"
            total={totalItems?.length!}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={itemsPerPage}
            setPerPage={setItemsPerPage}
          />
        )}
      </div>
    </>
  )
}

export default PremiumView
