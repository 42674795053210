import React, { useEffect, useState } from "react"
import {
  Button,
  CardBody,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { FiDownloadCloud } from "react-icons/fi"

import { Policies_Table_Head, Payments_Table_Head } from "./data"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import SendMessageDetails from "../../../components/Details/SendMessageDetails"
import { DriverModal } from "../../../components/modal/DriverModal"
import {
  GET_DRIVER_PAYMENTS,
  LIST_OF_ALL_DRIVER_POLICIES,
} from "../../../GraphQl/queries"
import { useQuery } from "@apollo/client"
import ScheduledDriverPaymentDetails from "../../../components/Details/ScheduledDriverPaymentDetails"
import moment from "moment"
import Papa from "papaparse"
import PayDetails from "../../../components/Details/pay-details"
import { DetailDrawer } from "../../../components"
import { RootState } from "../../../store/store"
import { useSelector } from "react-redux"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

export type localDriver = {
  driver_id: string
  driver_name: string
  driver_phone: string
  deposit_balance: number
  driver_status: string
  date_created: string
}

type DriverPolicy = {
  driver_name: string
  policy_id: string
  policy_number: number
  premium_balance: number
  driver_code: string
  premium_paid: number
  premium_payable: number
  registration_number: string
  scheduled_amount: number
  start_date: string
  end_date: string
  policyholder: string
}
type DriverPaymentsType = {
  __typename: string
  amount: number
  company_staff:
    | {
        __typename: string
        id: string
        name: string
        phone_number: string
      }
    | string
  datecreated: string
  id: string
  sub_agent: null
  transaction_id: string
  policy:
    | {
        __typename: string
        policy_number: string
        request: {
          __typename: string
          amount: string
          registration_number_text: string
        }
      }
    | string
  premium_balance: number
}

const ManageDrivers = () => {
  const [selectedfilter, setFilter] = useState("Policies")
  const [open, setOpen] = useState<boolean>(false)
  const [table_title, setTableTitle] = useState("Policies Status")
  const [openSendMessage, setOpenMessage] = useState<boolean>(false)
  const [openPaymentDetails, setOpenPaymentDetails] = useState<boolean>(false)
  const [payContent, setPayContent] = useState<DriverPaymentsType>()
  const [currentPagePay, setCurrentPagePay] = useState(0)
  const [currentPagePol, setCurrentPagePol] = useState(0)

  const [filterdownloadData, setFilterDownloadData] = useState<
    DriverPolicy[] | DriverPaymentsType[] | any[] | []
  >([])
  const [itemSelected, setItemSelected] = useState<DriverPolicy | null>(null)
  const onChangeFilter = (value: string) => {
    setFilter(value)
    setCurrentPagePay(0)
    setCurrentPagePol(0)
  }

  const {
  
    user: stateUser,
  } = useSelector((state: RootState) => state.auth)

  const driver = JSON.parse(
    localStorage.getItem("driver") || "{}"
  ) as localDriver

  const { loading, data: driverPolicy } = useQuery<{
    view_get_driver_policy_details: DriverPolicy[]
  }>(LIST_OF_ALL_DRIVER_POLICIES, {
    variables: {
      id: driver.driver_id,
    },
  })
  // const { loading:loadingDetails, data: driverPersonalDetails } = useQuery(GET_SINGLE_DRIVER, {
  //   variables: {
  //     id: driver.driver_id,
  //   },
  // })
  // console.log(driverPersonalDetails)

  // console.log(driverPolicy)
  const { loading: LOADINGPay, data: driverPayments } = useQuery<{
    driver_invest2insure_payment: DriverPaymentsType[]
  }>(GET_DRIVER_PAYMENTS, {
    variables: {
      id: driver.driver_id,
    },
  })
  // console.log(driverPayments)

  const [itemsPerPagePay, setItemsPerPagePay] = useState(10)
  const [itemsPerPagePol, setItemsPerPagePol] = useState(10)

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])
  // const PAGE_SIZE = 7
  //------payments

  const endOffset = currentPagePay + itemsPerPagePay
  const paginatedDataPay = driverPayments?.driver_invest2insure_payment?.slice(
    currentPagePay,
    endOffset
  )



  useEffect(() => {
    if (itemsPerPagePay) {
      setCurrentPagePay(0)
    }
  }, [itemsPerPagePay])

  useEffect(() => {
    if (itemsPerPagePol) {
      setCurrentPagePol(0)
    }
  }, [itemsPerPagePol])

  // const paginatedDataPay = driverPayments?.driver_invest2insure_payment?.slice(
  //   startIndexPay,
  //   endIndexPay
  // )

  //-policies

  const endOffsetPol = currentPagePol + itemsPerPagePol
  const paginatedDataPolicy =
    driverPolicy?.view_get_driver_policy_details?.slice(
      currentPagePol,
      endOffsetPol
    )



  // const paginatedDataPolicy =
  //   driverPolicy?.view_get_driver_policy_details?.slice(
  //     startIndexPol,
  //     endIndexPol
  //   )

  useEffect(() => {
    let filter = selectedfilter
    if (filter === "Policies") {
      const change = driverPolicy?.view_get_driver_policy_details?.map(
        (item) => ({
          policyholder: item?.policyholder,
          policy_number: item?.policy_number,
          registration_number: item?.registration_number,
          start_date: moment(item?.start_date).format("LLL"),
          end_date: moment(item?.end_date).format("LLL"),
          driver_name: item?.driver_name,
          premium_balance: (item?.premium_balance as number).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
          premium_paid: (item?.premium_paid as number).toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          }),
          premium_payable: (item?.premium_payable as number).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
          scheduled_amount: (item?.scheduled_amount as number).toLocaleString(
            "en-US",
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          ),
        })
      )
      setFilterDownloadData(change as any)
    } else if (filter === "Payments") {
      const change = driverPayments?.driver_invest2insure_payment!.map(
        (item) => {
          return {
            policy: (
              item.policy as {
                __typename: string
                policy_number: string
                request: {
                  __typename: string
                  amount: string
                  registration_number_text: string
                }
              }
            )?.policy_number,
            company_staff: (
              item.company_staff as {
                __typename: string
                id: string
                name: string
                phone_number: string
              }
            )?.name,
            amount: (item.amount as number).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }),
            premium_balance: (item.premium_balance as number).toLocaleString(
              "en-US",
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            ),
            datecreated: moment(item.datecreated).format("LLL"),
          } as any
        }
      )
      setFilterDownloadData(change!)
    }
    setFilter(filter)
  }, [
    driverPolicy?.view_get_driver_policy_details,
    driverPayments?.driver_invest2insure_payment,
    selectedfilter,
  ])

  // console.log(filterdownloadData)
  const handleDownload = () => {
    // Check if there is data to download
    if (filterdownloadData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    const firstItem = filterdownloadData[0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = filterdownloadData?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${selectedfilter}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  // console.log("selectedrow",selectedRow?.policy_id)

  return (
    <>
      <CustomHeader title="Manage Drivers" />
      {open ? (
        <DriverModal
          title="SCHEDULED PAYMENTS TABLE"
          open={open}
          size={"lg"}
          onClose={() => setOpen(false)}
          children={
            <ScheduledDriverPaymentDetails
              policy_Id={itemSelected?.policy_id!}
              name={itemSelected?.driver_name!}
              drivercode={itemSelected?.driver_code!}
            />
          }
        />
      ) : openSendMessage ? (
        <DriverModal
          title={
            <p className=" text-gray-800 text-[1.2rem]">
              {" "}
              Send message to:{" "}
              <span className=" font-bold text-black text-[1.5rem]">
                {" "}
                {driver?.driver_name}
              </span>
            </p>
          }
          open={openSendMessage}
          size={400}
          focus="message"
          onClose={() => setOpenMessage(false)}
          children={
            <SendMessageDetails
              driver={driver.driver_id!}
              onClose={() => setOpenMessage(false)}
            />
          }
        />
      ) : null}

      <DetailDrawer
        size={450}
        title="Payment detail"
        open={openPaymentDetails}
        children={<>{<PayDetails content={payContent} />}</>}
        onClose={() => setOpenPaymentDetails(false)}
      />

      <div className=" mt-10 w-full">
        <div className="my-5">
          <CardBody className="shadow-xl overflow-x-scroll px-0 py-0  rounded-md ">
            <table className=" bg-white w-full min-w-max table-auto text-left">
              <thead className=" text-center">
                <tr>
                  {["Name", "Phone number", "status ", "date registered"].map(
                    (head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    )
                  )}
                </tr>
              </thead>
              <tbody className=" text-center">
                <tr className="transition-all bg-gray-300 text-center">
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 ">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm capitalize "
                    >
                      {driver.driver_name}
                    </Typography>
                  </td>
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 ">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm capitalize "
                    >
                      {driver.driver_phone}
                    </Typography>
                  </td>
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50 ">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm capitalize "
                    >
                      <div className=" flex items-center gap-x-1 justify-center">
                        <p className="  w-2 h-2 rounded-full bg-green-600 flex items-center justify-center"></p>
                        <p>{driver.driver_status}</p>{" "}
                      </div>
                    </Typography>
                  </td>
                  <td className="px-4 py-3 border-b-2 border-blue-gray-50">
                    <Typography
                      variant="small"
                      color="blue-gray"
                      className="font-normal text-sm capitalize "
                    >
                      {moment(driver?.date_created).format("LLL")}{" "}
                    </Typography>
                  </td>
                </tr>
              </tbody>
            </table>
          </CardBody>
          <div
            onClick={() => setOpen(true)}
            className=" flex justify-end  items-center my-5 gap-x-5"
          >
            <div className="bg-red-300 p-4 rounded-lg shadow-md  text-center">
              <h2 className="text-xl font-bold text-white">Deposit Balance</h2>
              <p className="text-2xl font-black mt-2">
                {" "}
                GHS{" "}
                {Number.isNaN(parseFloat(String(driver?.deposit_balance)))
                  ? "0.00"
                  : parseFloat(String(driver?.deposit_balance)).toLocaleString(
                      "en-US",
                      {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      }
                    )}
              </p>
            </div>
          </div>
        </div>
        <Typography
          style={{
            color:
              colors?.length! > 1
                ? `${colors![0]?.toLowerCase()}`
                : `${DEFAULTPRIMARYCOLOR}`,
          }}
          className="capitalize font-semibold text-md lg:text-lg p-3"
        >
          {table_title}
        </Typography>
        <CardBody className="shadow-sm overflow-x-scroll w-full px-0 py-0  rounded-1xl">
          <div className="w-full">
            <div className="flex flex-col md:flex-row lg:flex-row gap-2 items-center justify-between bg-white p-3">
              <div className="flex flex-col md:flex-row lg:flex-row gap-3  w-full">
                <div className=" bg-white p-3 w-full">
                  <div className="flex flex-row items-center w-full flex-wrap md:flex-nowrap gap-y-4 justify-around gap-x-6 mb-6 mt-6  ">
                    <div className="flex items-center justify-evenly  w-full  mr-16 ">
                      <div className=" flex items-center justify-center w-full  ">
                        <Typography className="w-20">Filter by</Typography>
                        <select
                          value={selectedfilter}
                          onChange={(e) => {
                            const value = e.target.value
                            onChangeFilter(value)
                            setTableTitle(
                              value === "Payments"
                                ? "Policy Payments"
                                : "Policies Status"
                            )
                          }}
                          className="h-10 capitalize bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        >
                          <option selected disabled>
                            Select filter
                          </option>
                          {["Policies", "Payments"]?.map((item) => (
                            <option
                              className="capitalize"
                              value={item}
                              key={item}
                              label={item}
                            >
                              {item}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div
                      onClick={() => setOpenMessage(true)}
                      className=" bg-blue-600 cursor-pointer  px-4 w-48 py-2   text-white rounded"
                    >
                      Send Message
                    </div>

                    <div className="flex shrink-0 flex-col gap-2 sm:flex-row">
                      <Tooltip content="Export as CSV">
                        <Button
                          onClick={handleDownload}
                          variant="filled"
                          className="capitalize text-sm font-normal flex flex-wrap gap-2"
                          size="sm"
                        >
                          <FiDownloadCloud size={20} />
                          <span>Download csv</span>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {selectedfilter === "Policies" ? (
            <>
              <CardBody className=" bg-white  w-full px-0 py-0  rounded-b-3xl">
                <div
                  className="overflow-x-auto overflow-y-auto"
                  style={{ maxHeight: "30vh" }}
                >
                  <table className="w-full bg-white min-w-max table-auto">
                    <thead className="bg-white sticky z-50 top-0">
                      <tr>
                        {Policies_Table_Head.map((head: string) => (
                          <th
                            key={head}
                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4  text-center"
                          >
                            <Typography
                              color="black"
                              className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedDataPolicy?.map((item, index: number) => {
                        const isLast = index === paginatedDataPolicy?.length - 1
                        const classes = isLast
                          ? "px-2 py-3  text-center"
                          : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                        return (
                          <tr
                            key={index.toString()}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.policyholder}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.policy_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                {item?.registration_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                GH₵{" "}
                                {(
                                  parseFloat(item?.premium_payable as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                GH₵{" "}
                                {(
                                  parseFloat(item?.premium_paid as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                GH₵{" "}
                                {(
                                  parseFloat(item?.premium_balance as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize text-center "
                              >
                                <div className=" flex items-center gap-x-1 justify-center">
                                  <p className="  w-2 h-2 rounded-full bg-green-600 flex items-center justify-center"></p>
                                  <p>{driver.driver_status}</p>{" "}
                                </div>
                                {/* <div className=" flex items-center gap-x-1 justify-center">
                              {driver?.driver_status === "active" ? (
                                <>
                                  <p className="  w-2 h-2 rounded-full bg-red-600 flex items-center justify-center"></p>
                                  <p>{driver.driver_status}</p>{" "}
                                </>
                              ) : (
                                <>
                                  <p className="  w-2 h-2 rounded-full bg-green-600 flex items-center justify-center"></p>
                                  <p>{driver.driver_status}</p>{" "}
                                </>
                              )}
                            </div> */}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.start_date).format("LLL")}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.end_date).format("LLL")}
                              </Typography>
                            </td>

                            <td
                              className={classes}
                              onClick={(e) => {
                                e.stopPropagation()
                              }}
                            >
                              <Button
                                // onClick={() => handleRowClick(item)}
                                onClick={() => {
                                  setOpen(true)
                                  setItemSelected(item)
                                }}
                                variant="filled"
                                color="green"
                                className="font-normal capitalize"
                              >
                                Payment Schedule
                              </Button>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
              {loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-10 h-10" />
                </div>
              )}

              {paginatedDataPolicy?.length! === 0 && !loading && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="white" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {paginatedDataPolicy &&
                paginatedDataPolicy?.length !== 0 &&
                !loading && (
                  // <GeneralPagination
                  //   totalItems={
                  //     driverPolicy?.view_get_driver_policy_details?.length!
                  //   }
                  //   itemsPerPage={itemsPerPagePol}
                  //   currentPage={currentPagePol}
                  //   onPageChange={handlePageChangePol}
                  // />
                  <PerPageAndPagination
                  customColor="white"
                    total={
                      driverPolicy?.view_get_driver_policy_details?.length!
                    }
                    currentPage={currentPagePol}
                    setCurrentPage={setCurrentPagePol}
                    perPage={itemsPerPagePol}
                    setPerPage={setItemsPerPagePol}
                  />
            
                )}
            </>
          ) : (
            <>
              <CardBody className=" bg-white  w-full px-0 py-0 ">
                <div
                  className="overflow-x-auto overflow-y-auto"
                  style={{ maxHeight: "30vh" }}
                >
                  <table className="w-full bg-white min-w-max table-auto">
                    <thead className="bg-white sticky z-50 top-0">
                      <tr>
                        {Payments_Table_Head.map((head: string) => (
                          <th
                            key={head}
                            className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4  text-center"
                          >
                            <Typography
                              color="black"
                              className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                            >
                              {head}
                            </Typography>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {paginatedDataPay?.map((item: any, index: number) => {
                        const isLast = index === paginatedDataPay?.length - 1
                        const classes = isLast
                          ? "px-2 py-3  text-center"
                          : "px-2 py-3 border-b-2 border-blue-gray-50 text-center"

                        return (
                          <tr
                            onClick={() => {
                              setOpenPaymentDetails(true)
                              setPayContent(item)
                            }}
                            key={index.toString()}
                            className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                          >
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.transaction_id}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize "
                              >
                                GH₵{" "}
                                {(
                                  parseFloat(item?.amount as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {item?.policy.policy_number}
                              </Typography>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                {moment(item?.datecreated).format("LLL")}
                              </Typography>
                            </td>

                            <td className={classes}>
                              <div className="flex flex-col gap-2">
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Full name:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff.name}
                                  </span>
                                </Typography>
                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  Phone number:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff.phone_number}
                                  </span>
                                </Typography>

                                <Typography
                                  variant="small"
                                  color="blue-gray"
                                  className="font-bold text-xs capitalize "
                                >
                                  User type:{" "}
                                  <span className="font-normal">
                                    {item?.company_staff.__typename}
                                  </span>
                                </Typography>
                              </div>
                            </td>
                            <td className={classes}>
                              <Typography
                                variant="small"
                                color="blue-gray"
                                className="font-normal text-xs capitalize"
                              >
                                GH₵{" "}
                                {(
                                  parseFloat(item?.premium_balance as any) ?? 0
                                ).toLocaleString("en-US", {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                })}
                              </Typography>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </CardBody>
              {LOADINGPay && (
                <div className="mt-10 flex items-center justify-center">
                  <Spinner className="w-10 h-10" />
                </div>
              )}

              {paginatedDataPay?.length! === 0 && !LOADINGPay && (
                <div className="mt-10 flex items-center justify-center">
                  <Typography color="white" variant="h6">
                    No data found
                  </Typography>
                </div>
              )}

              {paginatedDataPay && paginatedDataPay?.length !== 0 && (
                // <GeneralPagination
                //   totalItems={
                //     driverPayments?.driver_invest2insure_payment?.length!
                //   }
                //   itemsPerPage={itemsPerPagePay}
                //   currentPage={currentPagePay}
                //   onPageChange={handlePageChangePay}
                // />
                <PerPageAndPagination
                customColor="white"
                  total={driverPayments?.driver_invest2insure_payment?.length!}
                  currentPage={currentPagePay}
                  setCurrentPage={setCurrentPagePay}
                  perPage={itemsPerPagePay}
                  setPerPage={setItemsPerPagePay}
                />
              )}
            </>
          )}
        </CardBody>
      </div>
    </>
  )
}

export default ManageDrivers
