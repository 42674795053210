import React, { useState, useEffect } from "react"
import {
  Button,
  CardBody,
  Input,
  Spinner,
  Tooltip,
  Typography,
} from "@material-tailwind/react"
import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { useQuery } from "@apollo/client"
import { POLICYWITHOUTSTICKERS } from "../../../GraphQl/queries"
import { uncreatedPolicyType } from "./dummydata"
import { UserType } from "../../../types/user"
import { DetailDrawer } from "../../../components"
import ResolvePolicyStickerDetails from "../../../components/Details/resolve-policy-sticker-details"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import { DEFAULTPRIMARYCOLOR } from "../../../util"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

interface BusinessClass {
  name: string
  __typename: "business_classes"
}

interface CustomerDetail {
  first_name: string
  last_name: string
  phone_number: string
  __typename: "customers"
}

interface CompanyStaff {
  id: string
  name: string
  phone_number: string
  __typename: "company_staff"
}

interface Request {
  registration_number: string
  amount: string
  business_class: BusinessClass
  cover_type: string
  customer_detail: CustomerDetail
  created_date: string
  company_staff: CompanyStaff
  agent_details: any // You may want to provide a more specific type here if possible
  insurer_company: string
  user_type: string
  __typename: "request"
}

interface Policyy {
  id: string
  policy_number: string
  request: Request
  __typename: "policy"
}

const Index = () => {
  const [open, setOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState<uncreatedPolicyType | null>(
    null
  )
  const [searchText, setRequestText] = useState("")
  const history = useHistory()

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const {
    companyId,
    roleId,
    usertype,
    user: stateUser,
    token,
  } = useSelector((state: RootState) => state.auth)
  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }
  const handleSearch = (e: any) => {
    setRequestText(e.target.value)
  }

  const { data, loading: loadingPolicies } = useQuery<{
    policy: Policyy[]
  }>(POLICYWITHOUTSTICKERS, {
    variables: {
      insurer_company: companyId,
    },
  })

  const policies = data?.policy ?? []

  const filterData = (filter: Policyy) => {
    const date = moment(filter.request.created_date).format("LLL")
    if (searchText === "") return true
    return (
      filter.policy_number.toLowerCase().includes(searchText.toLowerCase()) ||
      filter.request.registration_number
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.business_class.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.cover_type
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request?.user_type
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.customer_detail.first_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.customer_detail.last_name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.customer_detail.phone_number
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.company_staff.name
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.company_staff.phone_number
        .toLowerCase()
        .includes(searchText.toLowerCase()) ||
      date?.toLowerCase().includes(searchText.toLowerCase()) ||
      filter.request.agent_details?.first_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.agent_details?.last_name
        ?.toLowerCase()
        .includes(searchText.toLowerCase()) ||
      filter.request.agent_details?.phone_number
        ?.toLowerCase()
        .includes(searchText.toLowerCase())
    )
  }
  //-------------------
  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (searchText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [searchText, startDate, endDate, itemsPerPage])

  const handlePageChange = (page: number) => {
    setCurrentPage(page)
  }

  const startIndex = (currentPage - 1) * itemsPerPage
  const endIndex = startIndex + itemsPerPage

  const totalItems = data?.policy?.filter(filterData)?.filter((item: any) => {
    const normalizeDate = (date: Date | string): Date => {
      const normalizedDate = new Date(date)
      normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
      return normalizedDate
    }
    const from =
      (startDate as any) instanceof Date
        ? normalizeDate(startDate)
        : normalizeDate(startDate)
    const to =
      (endDate as any) instanceof Date
        ? normalizeDate(endDate)
        : normalizeDate(endDate)
    const dateCreated = normalizeDate(item?.request.created_date)
    // Convert date_created to Date object
    // Check if dateCreated falls within the range (inclusive)
    if (startDate && endDate) {
      return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
    } else if (startDate) {
      return dateCreated >= from // Only from date is set
    } else if (endDate) {
      return dateCreated <= to // Only to date is set
    }
    return true // No filter applied
  })
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)

  const handleDownload = () => {
    // Check if there is data to download
    if (data?.policy?.length === 0 || !data?.policy) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter
    if (searchText || startDate || endDate) {
      const type = totalItems?.map((item: any) => ({
        Registration_Number: item?.request?.registration_number,
        Policy_Number: item?.policy_number,
        Business_Class: item?.request.business_class?.name,
        Cover_Type: item.request.cover_type,
        Customer_Name: `${item?.request.customer_detail?.first_name} ${item?.request.customer_detail?.last_name}`,
        Customer_Pnone_No: item?.request?.customer_detail?.phone_number,
        Amount: `GHS ${(item?.request?.amount ?? 0).toLocaleString("en-US", {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`,
        Date_Created: moment(item?.created_date).format("LLL"),
      }))
      const firstItem = type![0]
      const headers = Object.keys(firstItem!)
      // Transform data to match headers (Simplified)
      const transformedData = type?.map((item: any) => {
        const transformedItem: Record<string, any> = {}
        headers.forEach((header) => {
          transformedItem[header] = item[header] // No need for JSON.stringify
        })
        return transformedItem
      })
      // Convert data to CSV format
      const csvData = Papa.unparse({
        fields: headers,
        data: transformedData!,
      })
      // Create a Blob and download link
      const blob = new Blob([csvData], { type: "text/csv" })
      const url = window.URL.createObjectURL(blob)
      // Create a download link
      const a = document.createElement("a")
      a.href = url
      a.download = `${"resolve_policy_sticker"}.csv`
      document.body.appendChild(a)
      // Trigger a click on the link to start the download
      a.click()
      // Cleanup
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
      return
    }
    const type = data?.policy?.map((item: any) => ({
      Registration_Number: item?.request?.registration_number,
      Policy_Number: item?.policy_number,
      Business_Class: item?.request.business_class?.name,
      Cover_Type: item.request.cover_type,
      Customer_Name: `${item?.request.customer_detail?.first_name} ${item?.request.customer_detail?.last_name}`,
      Customer_Pnone_No: item?.request?.customer_detail?.phone_number,
      Amount: `GHS ${(item?.request?.amount ?? 0).toLocaleString("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      Date_Created: moment(item?.created_date).format("LLL"),
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"resolve_policy_sticker"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      // const data: UserType = JSON.parse(userData)

      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [])

  return (
    <>
      <CustomHeader title="Resolve Policy Sticker Issues" />
      <DetailDrawer
        onClose={() => setOpen(false)}
        open={open}
        title="Policy Details"
        children={<ResolvePolicyStickerDetails content={selectedRow} />}
        size={450}
      />
      <div className="w-full rounded-lg lg:col-span-2 ">
        <div className="bg-black p-3 text-white">
          <Typography
            style={{
              color:
                colors?.length! > 1
                  ? `${colors![0]?.toLowerCase()}`
                  : `${DEFAULTPRIMARYCOLOR}`,
            }}
            className="text-lg"
          >
            List of All Policies that do not have stickers{" "}
            {data?.policy && (
              <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                {data?.policy?.length}
              </span>
            )}
          </Typography>
        </div>
        <div className="p-3 bg-white  rounded-b-xl">
          <CardBody className="shadow-none px-0 py-0   rounded-none">
            <div className=" flex flex-col gap-y-2">
              <div className="flex shrink-0 flex-col justify-end gap-2 flex-wrap lg:flex-row pl-3">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>

              <div className="p-3 flex gap-x-5 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={searchText}
                    onChange={handleSearch}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
                <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">From</label>
                    <Input
                      type="datetime-local"
                      value={startDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleStartDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="font-semibold text-gray-700">To</label>
                    <Input
                      type="datetime-local"
                      value={endDate.slice(0, -1)} // Remove 'Z' for input value
                      onChange={handleEndDateChange}
                      size="md"
                      className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                      labelProps={{
                        className: "before:content-none after:content-none",
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className=" bg-white w-full min-w-max table-auto text-center">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    {[
                      "Policy Number",
                      "Vehicle Registration number",
                      "Amount (GHS)",
                      "Business Class",
                      "Cover type",
                      "Customer Details",
                      "Agent/Staff Details",
                      "Date Requested",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {policies &&
                    paginatedData?.map((item: any, index: number) => {
                      const isLast = index === paginatedData?.length - 1
                      const classes = isLast
                        ? "px-4 py-2  text-center"
                        : "px-4 py-2 border-b-2 border-blue-gray-50 text-center"

                      return (
                        <tr
                          onClick={() => {
                            setSelectedRow(item)
                            setOpen(true)
                          }}
                          key={index.toString()}
                          className="hover:bg-gray-100 transition-all even:bg-gray-200"
                        >
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item.policy_number}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item.request.registration_number}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              GHC{" "}
                              {item?.request.amount.toLocaleString("en-US", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item.request.business_class.name}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {item.request.cover_type}
                            </Typography>
                          </td>
                          <td className={classes}>
                            <div>
                              <Typography
                                variant="small"
                                className="text-left capitalize font-normal text-xs"
                                color="blue-gray"
                              >
                                Name: {item?.request.customer_detail.first_name}{" "}
                                {item?.request.customer_detail.last_name}
                              </Typography>
                              <Typography
                                variant="small"
                                className="text-left capitalize font-normal text-xs"
                                color="blue-gray"
                              >
                                Phone number:{" "}
                                {item?.request.customer_detail.phone_number}
                              </Typography>
                            </div>
                          </td>

                          <td className={classes}>
                            <div>
                              <Typography
                                variant="small"
                                className="text-left capitalize font-normal text-xs"
                                color="blue-gray"
                              >
                                Name:{" "}
                                {item?.request?.agent_details?.first_name
                                  ? `${item.request.agent_details.first_name} ${
                                      item.request.agent_details.last_name ?? ""
                                    }`
                                  : item?.request?.company_staff?.name ?? ""}
                              </Typography>
                              <Typography
                                variant="small"
                                className="text-left capitalize font-normal text-xs"
                                color="blue-gray"
                              >
                                Phone number:{" "}
                                {item?.request.agent_details?.phone_number
                                  ? item.request.agent_details.phone_number
                                  : item?.request.company_staff?.phone_number}
                              </Typography>
                              <Typography
                                variant="small"
                                className="text-left capitalize font-normal text-xs"
                                color="blue-gray"
                              >
                                Usertype: {item?.request.user_type}
                              </Typography>
                            </div>
                          </td>
                          <td className={classes}>
                            <Typography
                              variant="small"
                              color="blue-gray"
                              className="font-normal text-xs capitalize "
                            >
                              {moment(item?.request.created_date).format("LLL")}
                            </Typography>
                          </td>

                          <td className={classes}>
                            <Button
                              onClick={() => {
                                localStorage.setItem(
                                  "selected-policy-id",
                                  item.id
                                )

                                history.push("/dashboard/manage-policy")
                              }}
                              size="sm"
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-teal"
                            >
                              Manage Policy
                            </Button>
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </div>
        {loadingPolicies && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        )}

        {paginatedData?.length! === 0 && !loadingPolicies && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {/* {paginatedData && paginatedData?.length !== 0 && !loadingPolicies && (
          <GeneralPagination
            totalItems={totalItems?.length!}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )} */}

        {paginatedData && paginatedData?.length !== 0 && !loadingPolicies && (
          <PerPageAndPagination
          customColor="white"
            total={totalItems?.length!}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            perPage={itemsPerPage}
            setPerPage={setItemsPerPage}
          />
        )}
      </div>
    </>
  )
}

export default Index
