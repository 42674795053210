import {
  Button,
  CardBody,

  Input,
  Spinner,

  Tooltip,
  Typography,
} from "@material-tailwind/react"
import React, { useEffect, useState, useMemo } from "react"
import {
  CustomDeleteModal,
  DetailDrawer,

} from "../../../components"
import { DEFAULTPRIMARYCOLOR } from "../../../util"

import { AdminType, UpdateType } from "../../../types/admin"
import {  toast } from "react-toastify"
import { UserType } from "../../../types/user"
import { DASHBOARD_USERTYPE } from "../../../types/role"
import {  useForm } from "react-hook-form"

import { CustomHeader } from "../../../components/headers/CustomHeader"
import { useHistory } from "react-router-dom"
import { useQuery } from "@apollo/client"
import { GETPAYMENTREQUISITIONDetail, GETCONSOLIDATEDPAYMENT_REQUISITION } from "../../../GraphQl/queries"
import ConfirmationDialog from "../../../config/confirmationbox"
import { DriverModal } from "../../../components/modal/DriverModal"
import DisApproveOrdres from "../../../components/Details/disaprovePaymentOrders"
import PaymentOrderDetail from "../../../components/Details/payment-order-drawer"
import moment from "moment"
import { FiDownloadCloud } from "react-icons/fi"
import Papa from "papaparse"
import Otp from "../../../components/modal/enterOtpModal"
import { useSelector } from "react-redux"
import { RootState } from "../../../store/store"
import { getColorCode } from "../../../colorCodes"
import PerPageAndPagination from "../../../components/paginations/perpageAndPagination"

type AccountDetails = {
  code: string
  branch: string
  bank_name: string
  acct_number: string
  account_name: string
}
type MomoDetails = {
  network: string
  number: string
}

type RequisitionType = {
  account_details: string
  momo_details: string
  amount_requisitioned: number
  policy_number: string
  created_at: string
  id: string
  insurance_company_id: string
  payee_details: string
  payee_id: string
  payee_name: string
  payee_number: string
  payee_type: string
  payment_stage: string
  provider_transaction_id: string
  policy_id: string
  rate: string
  transaction_type: string
  sub_class_name: string
  business_class_name: string
  risk_id: string
  reference: string
  reference_amount: string
  // amount_requisitioned: string;
  transaction_name: string
}



const PaymentOrders = () => {
  const history = useHistory()
  const [user, setUser] = useState<UserType>()
  const [requistionText, setRequistionSearch] = useState("")
  const [loading, setLoading] = useState(false)
  const [showDeleteModal, SetShowDeleteModal] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDrawer, setOpenDrawer] = React.useState(false)
  const [selectedRow, setSelectedRow] = useState<UpdateType>()
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const [showUpdateModal] = useState(false)
  const [loadingCreating, setLoadingCreating] = useState(false)
  const [openSendDispproveMsg, setOpenSendDispproveMsg] = useState(false)
  const [loadingRow, setLoadingRow] = useState("")
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [chosenId, setChosenId] = useState<RequisitionType>()
  const [selectedRowss, setSelectedRowss] = useState<RequisitionType>()
  const [requestType, setRequestType] = useState("")
  const [openOtp, setOpenOtp] = useState(false)
  const [requisitionId, setRequisitionId] = useState("")

  const {
    companyId,
 
    user: stateUser,
    token: stateToken,
  } = useSelector((state: RootState) => state.auth)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const handleStartDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setStartDate(formattedValue)
  }

  const handleEndDateChange = (event: any) => {
    const value = event.target.value
    const formattedValue = value + ":00Z" // Append seconds and 'Z' for timezone
    setEndDate(formattedValue)
  }
  // console.log(startDate, endDate)

  const defaultValues = useMemo(() => {
    return {
      ...selectedRow,
    }
  }, [ selectedRow])

  const {
 
    setValue,

  } = useForm<UpdateType>({
    defaultValues: defaultValues,
  })

  const {
    data: requisition,
    loading: loadingRequistion,
    refetch: refetchRequisition,
  } = useQuery<{
    view_payment_requistion_details: RequisitionType[]
    payment_requisition: any
  }>( GETCONSOLIDATEDPAYMENT_REQUISITION, {
    variables: {
      id: companyId,
      code: "BDD",
    },
  })

  const parseRequisitionData = (data: RequisitionType) => {
    const parsedAccountDetails: AccountDetails = JSON.parse(
      data.account_details
    )
    const parsedMomoDetails: MomoDetails = JSON.parse(data.momo_details)

    return {
      ...data,
      account_details: parsedAccountDetails,
      momo_details: parsedMomoDetails,
    }
  }



  const handleRequistionSerach = (e: any) => {
    setRequistionSearch(e.target.value)
  }



  const filterRequisition = (data: RequisitionType) => {
    const date = moment(data.created_at).format("LL")
    const isTextMatch =
      data?.payee_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      parseRequisitionData(data)
        .account_details.bank_name?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      parseRequisitionData(data)
        .account_details.account_name?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.policy_number
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.risk_id?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      data?.sub_class_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      parseRequisitionData(data)
        .momo_details.network?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      parseRequisitionData(data)
        .momo_details.number?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.account_details
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.provider_transaction_id
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_name
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.transaction_type
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.amount_requisitioned
        .toString()
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.reference?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      data?.payee_number
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      data?.payment_stage
        ?.toLowerCase()
        ?.includes(requistionText?.toLowerCase()) ||
      date?.toLowerCase()?.includes(requistionText?.toLowerCase()) ||
      data?.rate?.toLowerCase()?.includes(requistionText?.toLowerCase())
    return isTextMatch
  }

  const [currentPage, setCurrentPage] = useState(0)
  const [itemsPerPage, setItemsPerPage] = useState(10)

  useEffect(() => {
    if (requistionText || startDate || endDate || itemsPerPage) {
      setCurrentPage(0)
    }
  }, [requistionText, startDate, endDate, itemsPerPage])



  const totalItems = requisition?.view_payment_requistion_details
    ?.filter(filterRequisition)
    ?.filter((item: any) => {
      const normalizeDate = (date: Date | string): Date => {
        const normalizedDate = new Date(date)
        normalizedDate.setHours(0, 0, 0, 0) // Set time to midnight to include the entire day
        return normalizedDate
      }
      const from =
        (startDate as any) instanceof Date
          ? normalizeDate(startDate)
          : normalizeDate(startDate)
      const to =
        (endDate as any) instanceof Date
          ? normalizeDate(endDate)
          : normalizeDate(endDate)
      const dateCreated = normalizeDate(item.created_at)

      // Convert date_created to Date object
      // Check if dateCreated falls within the range (inclusive)
      if (startDate && endDate) {
        return dateCreated >= from && dateCreated <= to // Corrected to <= for endDate
      } else if (startDate) {
        return dateCreated >= from // Only from date is set
      } else if (endDate) {
        return dateCreated <= to // Only to date is set
      }
      return true // No filter applied
    })

  // const paginatedData = totalItems?.slice(startIndex, endIndex)
  
  const endOffset = currentPage + itemsPerPage
  const paginatedData = totalItems?.slice(currentPage, endOffset)



  const handleDownload = () => {
    // Check if there is data to download
    if (paginatedData?.length === 0) {
      alert("No data to download.")
      return
    }
    // Define custom headers based on the selected filter

    const type = paginatedData?.map((item: RequisitionType) => ({
      
      bank_name: JSON.parse(item?.account_details)?.bank_name,
      bank_branch: JSON.parse(item?.account_details)?.branch,
      momo_network: JSON.parse(item?.momo_details)?.network,
      momo_number: JSON.parse(item?.momo_details)?.number,
      amount_requisitioned: item?.amount_requisitioned,
      policy_number: item?.policy_number,
      created_at: moment(item?.created_at).format("LL"),

      insurance_company_id: item?.insurance_company_id,
      payee_name: item?.payee_name,
      payee_type: item?.payee_type,
      payee_number: item?.payee_number,
      
      
    }))
    const firstItem = type![0]
    const headers = Object.keys(firstItem!)
    // Transform data to match headers (Simplified)
    const transformedData = type?.map((item: any) => {
      const transformedItem: Record<string, any> = {}
      headers.forEach((header) => {
        transformedItem[header] = item[header] // No need for JSON.stringify
      })
      return transformedItem
    })
    // Convert data to CSV format
    const csvData = Papa.unparse({
      fields: headers,
      data: transformedData!,
    })
    // Create a Blob and download link
    const blob = new Blob([csvData], { type: "text/csv" })
    const url = window.URL.createObjectURL(blob)
    // Create a download link
    const a = document.createElement("a")
    a.href = url
    a.download = `${"payment-orders"}.csv`
    document.body.appendChild(a)
    // Trigger a click on the link to start the download
    a.click()
    // Cleanup
    window.URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }

  const userData = stateUser
  const [colors, setColors] = useState<string[]>()

  useEffect(() => {
    if (userData) {
      const colors = getColorCode(stateUser?.colors)

      setColors(colors)
    }
  }, [userData, stateUser?.colors])


  React.useEffect(() => {
    if (defaultValues) {
      Object.entries(defaultValues).forEach(([key, value]) => {
        setValue(key as keyof typeof defaultValues, value)
      })
    }
  }, [defaultValues])

  const handleDelete = async () => {
    setOpenDrawer(false)
    try {
      setLoading(true)
      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/auth/delete-admin`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: JSON.stringify({
            admin_id: selectedRow?.admin_id,
          }),
        }
      )

      const req = await response.json()

      if (response.ok) {
        toast.success("Admin deleted successfully")
        SetShowDeleteModal(false)
        if (user?.email === selectedRow?.email) {
          localStorage.clear()
          history?.push("/")
          //()
        }
      } else {
        toast.error(req?.msg)
      }
      setLoading(false)
    } catch (error) {
      toast.error("Error deleting admin")
      setLoading(false)
    }
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked && paginatedData) {
      const allRows = paginatedData.map((item) => item.id)
      setSelectedRows(allRows)
    } else {
      setSelectedRows([])
    }
  }

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    rowId: string
  ) => {
    if (event.target.checked) {
      setSelectedRows([...selectedRows, rowId])
    } else {
      setSelectedRows(selectedRows.filter((id) => id !== rowId))
    }
  }

  const handleCreateSinglePaymentOrder = async (data: any) => {
    try {
      setLoadingCreating(true)
      setLoadingRow(data)
      const payload = JSON.stringify([
        {
          policy_id: data?.policy_id,
          amount: data?.amount_requisitioned,
          transaction_id: data?.provider_transaction_id,
          reference: data?.reference,
          user_id: data?.payee_id

        },
      ])

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-payment-approval-v2?usertype=${DASHBOARD_USERTYPE.company}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: payload, // Place the body outside the headers object
        }
      )

      const req = await response.json()
      setLoadingCreating(false)
      if (response.ok) {
        refetchRequisition()
        toast.success("Payment paid successfully")
      } else {
        toast.error(req?.msg)
      }
      setLoadingCreating(false)
    } catch (error) {
      toast.error("Error making payment")
      setLoading(false)
    }
  }

  const handleCreateMultiplePaymentOrder = async () => {
    try {
      setLoadingCreating(true)

      const payload = paginatedData?.filter(item => selectedRows.includes(item.id))
        .map(row => ({
          policy_id: row.policy_id,
          amount: row.amount_requisitioned,
          transaction_id: row.provider_transaction_id,
          reference: row.reference,
          user_id: row.payee_id
        }));

      const response = await fetch(
        `${process.env.REACT_APP_BASE_URL}/create-payment-approval-v2?usertype=${DASHBOARD_USERTYPE.company}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${stateToken}`,
          },
          body: JSON.stringify(payload),
        }
      )

      const req = await response.json()
      setLoadingCreating(false)
      if (response.ok) {
        toast.success("Payment made successfully")
        refetchRequisition()
      } else {
        toast.error(req?.msg)
      }
      setLoadingCreating(false)
    } catch (error) {
      toast.error("Error making payment")
      setLoading(false)
    }
  }

  React.useEffect(() => {
    const data = localStorage.getItem("user")
    if (data) {
      setUser(JSON.parse(data))
    }
  }, [])
  return (
    <>
      <CustomDeleteModal
        open={showDeleteModal}
        onClose={() => SetShowDeleteModal(false)}
        onSubmit={handleDelete}
        loading={loading}
        title="Delete selected admin"
      />
      <DetailDrawer
        size={450}
        title="Payment detail"
        open={open}
        children={<>{<PaymentOrderDetail content={selectedRowss} />}</>}
        onClose={() => setOpen(false)}
      />

      <ConfirmationDialog
        show={showConfirmation}
        setShow={setShowConfirmation}
        onConfirm={() => setOpenOtp(true)}
      />
      <DriverModal
        title="Enter Your PIN"
        open={openOtp}
        size={"sm"}
        focus="message"
        onClose={() => setOpenOtp(false)}
        children={
          <Otp
            onConfirm={() =>
              requestType === "approve"
                ? handleCreateSinglePaymentOrder(chosenId)
                : requestType === "disapprove"
                ? setOpenSendDispproveMsg(true)
                : handleCreateMultiplePaymentOrder()
            }
            onClose={() => setOpenOtp(false)}
          />
        }
        // () => handleCreateSinglePaymentOrder(chosenId)
      />
      <DriverModal
        title={
          <p className=" text-gray-800 text-[1.2rem]">Disapprove Order?</p>
        }
        open={openSendDispproveMsg}
        size={400}
        focus="message"
        onClose={() => setOpenSendDispproveMsg(false)}
        children={
          <DisApproveOrdres
            driver={""}
            onClose={() => setOpenSendDispproveMsg(false)}
            onConfirm={() => refetchRequisition()}
            getRequisitionId={requisitionId}
          />
        }
      />

      <DetailDrawer
        size={350}
        title="Admin detail"
        open={openDrawer}
        children={<>{/* <RenderAdminDetail content={selectedRow} /> */}</>}
        onClose={() => setOpenDrawer(false)}
      />

      <CustomHeader title="Payment Order" />

      <div className="mt-10">
        <CardBody className="shadow-sm sticky px-0 py-0  rounded-3xl w-full">
          <div className="bg-black pt-5 rounded-t-2xl">
            <Typography
              variant="h6"
              style={{
                color:
                  colors?.length! > 1
                    ? `${colors![0]?.toLowerCase()}`
                    : `${DEFAULTPRIMARYCOLOR}`,
              }}
              className="px-4 pb-4  font-bold leading-none opacity-70 capitalize"
            >
              List of All Outstanding Payments Orders
              {requisition?.view_payment_requistion_details && (
                <span className="text-white text-sm bg-admin-red p-1 rounded-md">
                  {requisition?.view_payment_requistion_details?.length}
                </span>
              )}
            </Typography>
          </div>
          <div className="w-full">
            <div className="w-full  flex flex-col gap-y-4 bg-white p-3">
              <div className="flex shrink-0 flex-col gap-2 justify-end sm:flex-row pl-10">
                <Tooltip content="Export as CSV">
                  <Button
                    onClick={handleDownload}
                    variant="filled"
                    className="capitalize text-sm font-normal flex flex-wrap gap-2"
                    size="sm"
                  >
                    <FiDownloadCloud size={20} />
                    <span>Download csv</span>
                  </Button>
                </Tooltip>
              </div>
              <div className="p-3 flex gap-x-2 items-center flex-col 2xl:flex-row gap-y-3 mb-5">
                {selectedRows?.length > 0 && (
                  <Button
                    // onClick={() => handleCreateMultiplePaymentOrder()}
                    onClick={() => {
                      setRequestType("multiple")
                      setOpenOtp(true)
                    }}
                    size="sm"
                    color="teal"
                    className="text-xs font-normal capitalize bg-brand-cyan w-64"
                  >
                    {loadingCreating ? "loading..." : "Approve payment"}
                  </Button>
                )}
                <div className="w-full mr-3">
                  <Input
                    crossOrigin="anonymous"
                    label="Search"
                    value={requistionText}
                    onChange={handleRequistionSerach}
                    icon={<i className="fas fa-search text-base"></i>}
                  />
                </div>
            
              <div className="flex flex-col xl:flex-row gap-3 justify-between mb-0">
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">From</label>
                  <Input
                    type="datetime-local"
                    value={startDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleStartDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
                <div className="flex items-center space-x-2">
                  <label className="font-semibold text-gray-700">To</label>
                  <Input
                    type="datetime-local"
                    value={endDate.slice(0, -1)} // Remove 'Z' for input value
                    onChange={handleEndDateChange}
                    size="md"
                    className="!border-t-blue-gray-200 focus:!border-t-gray-900 w-full"
                    labelProps={{
                      className: "before:content-none after:content-none",
                    }}
                  />
                </div>
              </div>
              </div>
            </div>
          </div>
          <CardBody className="px-0 py-0">
            <div
              className="overflow-x-auto overflow-y-auto"
              style={{ maxHeight: "65vh" }}
            >
              <table className="w-full bg-white min-w-max table-auto">
                <thead className="bg-white sticky z-50 top-0">
                  <tr>
                    <th className="border-y border-blue-gray-100 text-black bg-gray-50/50 p-4">
                      <input
                        onClick={(e) => {
                          e.stopPropagation()
                        }}
                        type="checkbox"
                        onChange={handleSelectAll}
                        checked={selectedRows.length === paginatedData?.length}
                      />
                    </th>
                    {[
                      "Payment Transaction Type",
                      "Transaction Reference",
                      "Provider Transaction Id",
                      "Policy Details",
                      "Payee Details",
                      "Payment Details",
                      "Account Details",
                      "Momo Details",
                      "Date Created",
                      "Amount",
                      "",
                      "",
                    ].map((head) => (
                      <th
                        key={head}
                        className=" border-y border-blue-gray-100 text-black bg-gray-50/50 p-4"
                      >
                        <Typography
                          color="black"
                          className="font-bold text-sm text-black leading-none opacity-70 capitalize"
                        >
                          {head}
                        </Typography>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {paginatedData?.map((item, index: number) => {
                  
                    const isLast = index === paginatedData?.length - 1
                    const classes = isLast
                      ? "px-2 py-4  text-center"
                      : "px-2 py-4 border-b-2 border-blue-gray-50 text-center"

                    return (
                      <tr
                        key={item?.id + index.toString()}
                        onClick={() => {
                          setSelectedRowss(item)
                          setOpen(true)
                        }}
                        className="hover:bg-gray-100 transition-all hover:cursor-pointer"
                      >
                        <td className={classes}>
                          <input
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                            type="checkbox"
                            onChange={(event) =>
                              handleRowCheckboxChange(event, item.id)
                            }
                            checked={selectedRows.includes(item.id)}
                          />
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize "
                          >
                            {item?.transaction_type}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.reference}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {item?.provider_transaction_id}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Policy Number:{" "}
                            <span className="font-normal">
                              {item?.policy_number}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Class/Subclass:{" "}
                            <span className="font-normal">
                              {item?.business_class_name +
                                " / " +
                                item?.sub_class_name}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Risk ID.:{" "}
                            <span className="font-normal">{item?.risk_id}</span>
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Client Name:{" "}
                            <span className="font-normal">
                              {item?.payee_name}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Client Type:{" "}
                            <span className="font-normal">
                              {item?.payee_type}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Phone No.:{" "}
                            <span className="font-normal">
                              {item?.payee_number}
                            </span>
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            transaction_type:{" "}
                            <span className="font-normal">
                              {item?.transaction_type}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Reference Amount:{" "}
                            <span className="font-normal">
                              {item?.reference_amount}
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Rate:{" "}
                            <span className="font-normal">{item?.rate} %</span>
                          </Typography>
                        </td>

                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Bank:{" "}
                            <span className="font-normal">
                              {
                                parseRequisitionData(item).account_details
                                  .bank_name
                              }
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Branch:{" "}
                            <span className="font-normal">
                              {
                                parseRequisitionData(item).account_details
                                  .branch
                              }
                            </span>
                          </Typography>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            A/C No:{" "}
                            <span className="font-normal">
                              {
                                parseRequisitionData(item).account_details
                                  .acct_number
                              }
                            </span>
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Network:{" "}
                            <span className="font-normal">
                              {parseRequisitionData(item).momo_details.network}
                            </span>
                          </Typography>

                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-bold text-xs capitalize text-left pl-7"
                          >
                            Wallet No:{" "}
                            <span className="font-normal">
                              {parseRequisitionData(item).momo_details.number}
                            </span>
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {moment(item?.created_at).format("LL")}
                          </Typography>
                        </td>
                        <td className={classes}>
                          <Typography
                            variant="small"
                            color="blue-gray"
                            className="font-normal text-xs capitalize"
                          >
                            {"GHS " +
                              (item.amount_requisitioned ?? 0).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )}
                          </Typography>
                        </td>
                        <div className="flex justify-between whitespace-nowrap z-50 bg-white sticky right-0">
                          <td className={classes}>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation()
                                setChosenId(item)
                                setShowConfirmation(true)
                                setRequestType("approve")
                              }}
                              
                              size="sm"
                              disabled={loadingRow === item?.id}
                              color="teal"
                              className="text-xs font-normal capitalize bg-brand-cyan"
                            >
                              {loadingRow === item.id
                                ? "Loading"
                                : "Approve Order"}

                              {/* {loadingRow === item.payable_commission_id ? "Loading" : "Request to pay"} */}
                            </Button>
                          </td>
                          <td className={classes}>
                            <Button
                              onClick={(e) => {
                                e.stopPropagation()
                                setRequisitionId(item?.policy_id)
                                setOpenOtp(true)
                                setRequestType("disapprove")
                                // setOpenSendDispproveMsg(true)
                                // setShowConfirmation(true)
                              }}
                              // onClick={() => { setChosenId(item?.payable_commission_id); setShowConfirmation(true); }}
                              size="sm"
                              disabled={loadingRow === item?.id}
                              color="red"
                              className="text-xs font-normal capitalize bg-brand-cyan"
                            >
                              {loadingRow === item.id
                                ? "Loading"
                                : "Disapprove Order"}

                              {/* {loadingRow === item.payable_commission_id ? "Loading" : "Request to pay"} */}
                            </Button>
                          </td>
                        </div>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
            </div>
          </CardBody>
        </CardBody>

        {loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-10 h-10" />
          </div>
        )}

        {paginatedData?.length! === 0 && !loadingRequistion && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )}

        {paginatedData?.length !== 0 && !loadingRequistion && (
          // <GeneralPagination
          //   totalItems={totalItems?.length!}
          //   itemsPerPage={itemsPerPage}
          //   currentPage={currentPage}
          //   onPageChange={handlePageChange}
          // />

          <PerPageAndPagination
          customColor="white"
          total={totalItems?.length!}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          perPage={itemsPerPage}
          setPerPage={setItemsPerPage}
        />
        )}
        {/* {isLoading && (
          <div className="mt-10 flex items-center justify-center">
            <Spinner className="w-1o h-10" />
          </div>
        )} */}

        {/* {!paginatedData && !isLoading && (
          <div className="mt-10 flex items-center justify-center">
            <Typography color="white" variant="h6">
              No data found
            </Typography>
          </div>
        )} */}

        {/* Paginator */}
        {/* {paginatedData && (
          <div className="my-10 flex items-center justify-center">
            <div className="flex items-center gap-8">
              <IconButton
                size="sm"
                variant="outlined"
                onClick={prevPage}
                disabled={activeRequistionPage === 1}
              >
                <AiOutlineArrowLeft strokeWidth={2} className="h-4 w-4" />
              </IconButton>
              <Typography color="gray" className="font-normal">
                Page{" "}
                <strong className="text-gray-900">
                  {activeRequistionPage}
                </strong>{" "}
                of{" "}
                <strong className="text-gray-900">
                  {Math.ceil(
                    (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                  )}
                </strong>
              </Typography>
              <IconButton
                size="sm"
                variant="outlined"
                onClick={nextPage}
                disabled={
                  activeRequistionPage ===
                  Math.ceil(
                    (data?.admins?.filter(filterData).length || 0) / PAGE_SIZE
                  )
                }
              >
                <AiOutlineArrowRight strokeWidth={2} className="h-4 w-4" />
              </IconButton>
            </div>
          </div>
        )} */}
      </div>
    </>
  )
}

export default PaymentOrders
